import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  IconButton,
  Box,
  Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { FiberManualRecord } from '@material-ui/icons';
import { SUBROWS_TABLE_COLUMN_HEADERS } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%'
  },
  containerScrollable: {
    height: '100%',
    maxHeight: 533
  },
  fullScreen: {
    height: '100%',
    maxHeight: 725
  },
  table: {
    overflowY: 'hidden',
    minWidth: '500px'
  },
  smallTable: {
    minWidth: '320px'
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.primary.contrastText
  },
  tableBody: {},
  tableCell: {
    whiteSpace: 'nowrap'
  },
  textInput: {
    backgroundColor: theme.palette.inverted.main,
    borderRadius: 5
  },
  selected: {
    backgroundColor: theme.palette.secondary.light
  },
  sortButton: {
    color: theme.palette.primary.contrastText,
    borderRadius: '0'
  },
  arrow: {
    paddingLeft: theme.spacing(1)
  },
  checkBox: {
    padding: '0px',
    marginRight: '5px',
    marginBottom: '3px',
    color: '#bdbfbe'
  }
}));

export const StripedTable = ({
  headers = [],
  children,
  align,
  scrollable,
  isRepeatPage,
  isFullScreen = false,
  selectedSortParam,
  selectedSortType,
  setSelectedSortParam,
  setSelectedSortType,
  markAllRowsNoGo,
  markAllRowsCompleted,
  markAllRowsKeepOutZone,
  markAllRowsCleanedUp,
  markAllRowsMuddy,
  updateSubrow,
  isSolarRowTable,
  isSolarRgPage
}) => {
  const classes = useStyles();

  const flipSortType = () => {
    if (selectedSortType === 'asc') setSelectedSortType('des');
    else setSelectedSortType('asc');
  };

  const handleSortParam = (id) => {
    if (selectedSortParam === id) flipSortType();
    else {
      setSelectedSortType('asc');
      setSelectedSortParam(id);
    }
  };

  const isObject = (variable) => {
    if (variable.length) return !!variable[0] && variable[0].constructor === Object;
    return false;
  };

  const areAllValuesTrue = (header) => {
    let checked = false;
    if (header.name === SUBROWS_TABLE_COLUMN_HEADERS.ROBOTIC_CUT) {
      checked = markAllRowsCompleted;
    } else if (header.name === SUBROWS_TABLE_COLUMN_HEADERS.CLEANED_UP) {
      checked = markAllRowsCleanedUp;
    } else if (header.name === SUBROWS_TABLE_COLUMN_HEADERS.IS_NO_GO) {
      checked = markAllRowsNoGo;
    } else if (header.name === SUBROWS_TABLE_COLUMN_HEADERS.KEEP_OUT) {
      checked = markAllRowsKeepOutZone;
    } else if (header.name === SUBROWS_TABLE_COLUMN_HEADERS.IS_MUDDY) {
      checked = markAllRowsMuddy;
    }
    return checked;
  };

  return (
    <TableContainer
      component={Paper}
      className={scrollable ? (isFullScreen ? classes.fullScreen : classes.containerScrollable) : classes.container}
    >
      <Table stickyHeader className={`${classes.table} ${isRepeatPage ? classes.smallTable : ''}`}>
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell className={classes.tableHeader} key={i} align={align}>
                {isObject(headers) && !isSolarRowTable ? (
                  header.isSortable ? (
                    <Tooltip title={<Typography>Sort by {header.name}</Typography>}>
                      <IconButton disableRipple className={classes.sortButton} size="small" onClick={() => handleSortParam(header.id)}>
                        <Typography component="p" variant="subtitle1">
                          {header.name}
                        </Typography>
                        {header.id === selectedSortParam && selectedSortType === 'asc' && (
                          <div className={classes.arrow}>
                            <KeyboardArrowDownRoundedIcon size="small" />
                          </div>
                        )}
                        {header.id === selectedSortParam && selectedSortType === 'des' && (
                          <div className={classes.arrow}>
                            <KeyboardArrowUpRoundedIcon size="small" />
                          </div>
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Typography component="p" variant="subtitle1">
                      {header.name}
                    </Typography>
                  )
                ) : typeof header === 'object' && isSolarRowTable ? (
                  <Box display="flex" alignItems="center">
                    {i !== 0 && (
                      <Checkbox
                        checked={areAllValuesTrue(header)}
                        onChange={(event) => {
                          updateSubrow(event, header.field);
                        }}
                        className={classes.checkBox}
                      />
                    )}
                    <Typography component="p" variant="subtitle1">
                      {header.name}
                    </Typography>
                    {header?.name !== 'Name' && isSolarRgPage && (
                      <FiberManualRecord fontSize="medium" style={{ color: `${header?.colour || ''}`, marginBottom: '3px' }} />
                    )}
                  </Box>
                ) : (
                  <Typography component="p" variant="subtitle1">
                    {header}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
