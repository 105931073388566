import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { ensureWebComponentIsLoaded } from '../../utils/external-scripts.utls';
import { useStores } from '../../store/root/root.store';
import { HIGH_LATENCY_THRESHOLD_SECONDS, IN_REVERSE_VELOCITY_THRESHOLD } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  root: {
    background: 'rgba(32, 32, 32, 1.0)',
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '1090px'
  },
  webComponentRemoteGuardian: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // video3 -> left, video1 -> rear, video0 -> front, video2 -> right
    '& .webrtc-video3': {
      position: 'absolute',
      left: '0%',
      width: '25.0%'
    },
    '& .webrtc-video1': {
      position: 'absolute',
      top: '520px',
      left: '0%',
      width: '25.0%',
      zIndex: '1'
    },
    '& .webrtc-video0': {
      position: 'absolute',
      left: '25.0%',
      width: '50.0%'
    },
    '& .webrtc-video2': {
      position: 'absolute',
      left: '75.0%',
      width: '25.0%',
      zindex: '1'
    }
  },
  webComponentRemoteGuardianRearFOV: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // video3 -> left, video1 -> rear, video0 -> front, video2 -> right
    '& .webrtc-video3': {
      position: 'absolute',
      left: '0%',
      width: '25.0%'
    },
    '& .webrtc-video1': {
      position: 'absolute',
      left: '25.0%',
      width: '50.0%'
    },
    '& .webrtc-video0': {
      position: 'absolute',
      top: '520px',
      left: '0%',
      width: '25.0%',
      zIndex: '1'
    },
    '& .webrtc-video2': {
      position: 'absolute',
      left: '75.0%',
      width: '25.0%',
      zindex: '1'
    }
  },
  webComponentVideocontainer: {
    '& .webrtc-video-wrapper': {
      display: 'grid',
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      width: '100%',
      backgroundColor: 'black',
      gridTemplateColumns: '0.7fr 0.9fr 1.4fr;',
      gridTemplateRows: '1.5fr 0.5fr 1fr',
      gap: '0px 0px',
      gridAutoFlow: 'row',
      gridTemplateAreas: `"rearCamera leftCamera frontCamera"
      "rearCamera rightCamera frontCamera"
      ". rightCamera frontCamera"`
    },
    '& .webrtc-video0': {
      gridArea: 'frontCamera',
      height: '502px',
      width: '550px',
      maxHeight: '100%',
      maxWidth: '100%',
      margin: '0px',
      padding: '0px'
    },
    '& .webrtc-video1': {
      gridArea: 'rearCamera',
      height: '240px',
      width: '100%',
      maxWidth: '100%'
    },
    '& .webrtc-video2': {
      gridArea: 'leftCamera',
      height: '240px',
      width: '100%',
      maxHeight: '95%',
      maxWidth: '100%'
    },
    '& .webrtc-video3': {
      gridArea: 'rightCamera',
      height: '240px',
      width: '100%',
      marginTop: '0px',
      maxHeight: '95%',
      maxWidth: '100%'
    }
  },
  webComponentVideocontainerRearCameraFOV: {
    '& .webrtc-video-wrapper': {
      display: 'grid',
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      width: '100%',
      backgroundColor: 'black',
      gridTemplateColumns: '0.7fr 0.9fr 1.4fr;',
      gridTemplateRows: '1.5fr 0.5fr 1fr',
      gap: '0px 0px',
      gridAutoFlow: 'row',
      gridTemplateAreas: `"frontCamera leftCamera rearCamera"
      "frontCamera rightCamera rearCamera"
      ". rightCamera rearCamera"`
    },
    '& .webrtc-video0': {
      gridArea: 'frontCamera',
      height: '240px',
      width: '100%',
      maxWidth: '100%',
      margin: '0px',
      padding: '0px'
    },
    '& .webrtc-video1': {
      gridArea: 'rearCamera',
      height: '502px',
      width: '550px',
      maxHeight: '100%',
      maxWidth: '100%',
      margin: '0px',
      padding: '0px'
    },
    '& .webrtc-video2': {
      gridArea: 'leftCamera',
      height: '240px',
      width: '100%',
      maxHeight: '95%',
      maxWidth: '100%'
    },
    '& .webrtc-video3': {
      gridArea: 'rightCamera',
      height: '240px',
      width: '100%',
      marginTop: '0px',
      maxHeight: '95%',
      maxWidth: '100%'
    }
  },
  webComponentMultiAuditTwoCamera: {
    '& .webrtc-video-wrapper': {
      display: 'grid',
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      width: '100%',
      backgroundColor: 'black',
      gridTemplateColumns: '1fr 0.5fr 1.5fr;',
      gridTemplateRows: '1fr 1fr 1fr',
      gap: '0px 0px',
      gridAutoFlow: 'row',
      gridTemplateAreas: `"rearCamera rearCamera frontCamera"
      "rearCamera rearCamera frontCamera"
      "rearCamera rearCamera frontCamera"`,
      boxSizing: 'border-box'
    },
    '& .webrtc-video0': {
      gridArea: 'frontCamera',
      height: '500px',
      width: '560px',
      maxHeight: '100%',
      maxWidth: '100%',
      margin: '0px',
      padding: '0px'
    },
    '& .webrtc-video1': {
      gridArea: 'rearCamera',
      height: '500px',
      width: '560px',
      maxHeight: '100%',
      maxWidth: '100%',
      margin: '0px',
      padding: '0px'
    },
    '& .webrtc-video2': {
      display: 'none',
      gridArea: 'leftCamera',
      height: '0px',
      width: '0%',
      maxHeight: '95%',
      maxWidth: '100%'
    },
    '& .webrtc-video3': {
      display: 'none',
      gridArea: 'rightCamera',
      height: '0px',
      width: '0%',
      marginTop: '0px',
      maxHeight: '95%',
      maxWidth: '100%'
    }
  }
}));

const VideoStreamMultiPageView = React.memo(
  observer(({ serialNumber, hide = false, remoteGuardianView = false, auditorView, dualCameraMode = false }) => {
    const { MultiRobotsStore } = useStores();
    const streamToken = MultiRobotsStore.robots.get(serialNumber)?.streamToken;
    const isReversing = MultiRobotsStore.robots.get(serialNumber)?.linearVelocity < IN_REVERSE_VELOCITY_THRESHOLD;
    const videoDeviceRef = useRef({});
    const isListenerAttached = useRef(false);
    const isMounted = useRef(true);
    const classes = useStyles();

    useEffect(() => {
      if (!remoteGuardianView) {
        ensureWebComponentIsLoaded(`d_${serialNumber}`, () => MultiRobotsStore.getRobotStreamToken(serialNumber), true);
      }
      // return () => {
      //   const script = document.getElementById('webrtc-web-component-video');
      //   if (script) script.remove();
      // };
    }, [serialNumber]);

    // Monitor video streaming lag. If lag > 500ms, highlatency will be set to true, disabling teleops
    useEffect(() => {
      let timeOutId;
      const attachOnLagListener = () => {
        if (videoDeviceRef?.current && !isListenerAttached.current) {
          const handleLag = (lag) => {
            if (lag.lag > HIGH_LATENCY_THRESHOLD_SECONDS) {
              MultiRobotsStore.updateLatency(serialNumber, true, lag.lag);
            } else {
              MultiRobotsStore.updateLatency(serialNumber, false, lag.lag);
            }
          };
          videoDeviceRef?.current?.call('onLag', handleLag);
          isListenerAttached.current = true;
        } else if (!isListenerAttached.current && isMounted.current) {
          console.error('Failed to attach videDeviceRef');
        }
      };
      setTimeout(() => {
        if (isMounted.current) {
          timeOutId = attachOnLagListener();
        }
      }, 2500);

      return () => {
        clearTimeout(timeOutId);
        isMounted.current = false;
      };
    }, []);

    if (streamToken && remoteGuardianView && !auditorView) {
      return (
        <div className={classes.root}>
          <webrtc-video-device
            class={isReversing ? classes.webComponentRemoteGuardianRearFOV : classes.webComponentRemoteGuardian}
            id="swap"
            host="transitiverobotics.com"
            ssl="true"
            jwt={streamToken}
            use-default="true"
            timeout="86400"
            exclusive="true"
            ref={videoDeviceRef}
          />
        </div>
      );
    }
    if (streamToken && !hide && !auditorView) {
      return (
        <div>
          <webrtc-video-device
            class={isReversing ? classes.webComponentVideocontainerRearCameraFOV : classes.webComponentVideocontainer}
            id="swap"
            host="transitiverobotics.com"
            ssl="true"
            jwt={streamToken}
            use-default="true"
            timeout="86400"
            exclusive="true"
            ref={videoDeviceRef}
          />
        </div>
      );
    }
    if (streamToken && !hide && auditorView) {
      return (
        <div>
          <webrtc-video-device
            class={dualCameraMode ? classes.webComponentMultiAuditTwoCamera : classes.webComponentVideocontainer}
            id="swap"
            host="transitiverobotics.com"
            ssl="true"
            jwt={streamToken}
            use-default="true"
            timeout="86400"
            exclusive="true"
            ref={videoDeviceRef}
          />
        </div>
      );
    }
    return null;
  })
);

export default VideoStreamMultiPageView;
