import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Button, makeStyles } from '@material-ui/core';
import { formatStringForReadbility } from '../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    maxHeight: 300,
    maxWidth: 350
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: theme.spacing(0.5)
  },
  gridLabel: {
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
    textTransform: 'capitalize'
  },
  gridValue: {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    fontFamily: 'Arial, sans-serif',
    color: theme.palette.text.secondary
  },
  dialogTitle: { display: 'flex', justifyContent: 'center' },
  dialogActions: {
    padding: theme.spacing(0.5)
  },
  button: {
    fontSize: '0.875rem',
    padding: theme.spacing(1),
    border: '2px solid grey'
  }
}));

/**
 * This dialogue component is responsible for displaying the
 * values of saved parameters for solar paths generation in
 * a readable format
 * @param {Boolean} open handles the show/hide state of component
 * @param {Function} onClose callback action sets open to false to close dialogue
 * @param {Object} data Object containing path generation parameters values
 * @returns {Component} React dialogue component
 */
const PathGenerationParamsDisplay = ({ open, onClose, data }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>{data?.name || 'NA'}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={3}>
          {Object.entries(data).map(([key, value]) => (
            <React.Fragment key={`${key}_${value}`}>
              <Grid item xs={6} className={classes.gridItem}>
                <Typography variant="body1" className={classes.gridLabel}>
                  {formatStringForReadbility(key)}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <Typography variant="body1" className={classes.gridValue}>
                  {formatStringForReadbility(value) || 'NULL'}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} className={classes.button}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PathGenerationParamsDisplay;
