import { Grid, makeStyles, Paper, Typography, Button, Divider, createTheme, ThemeProvider, TextField, Fade } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import { useObserver } from 'mobx-react-lite';
import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';
import { useEnvironment } from '../environment';
import { ROUTE_PATHS_STUDIO } from './routes';
import '../App.css';
import euler_to_quaternion from 'euler-to-quaternion';
import { uploadAllPathsFile, uploadOpenAreaFile } from '../services/api/routes.service';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';
import SaveRouteConfirmation from '../components/dialogs/save-route-confirmation.dialog';
import LoadingDialog from '../components/dialogs/loading-dialog.dialog';
import { addRouteToSubrowsTable, getSubBlockSubrows } from '../services/api/subrows.service';
import { guardedClient } from '../utils/axios-instance';
import { DEFAULT_OPEN_AREA_PATH_TYPE } from '../utils/constants';
import { clampAngle } from '../utils/ui.utils';

const utmObj = require('utm-latlng');

const useStyles = makeStyles((theme) => ({
  detailPanel: {
    padding: theme.spacing(2),
    height: '100%'
  },
  innerPanel: {
    height: '100%'
  },
  map: {
    // Have to define a height for the map
    height: '700px'
  },
  noPrint: {
    '@media print': {
      display: 'none'
    }
  },
  input: {
    backgroundColor: theme.palette.inverted.main
  }
}));

const theme = createTheme({
  typography: {
    fontSize: 20
  }
});

let poly = null;
let pathDistance = 0;
let csvArray = [];

/**
 * TeachRoutePage - Draw and add open area paths
 *
 * This component is responsible for adding new routes.
 * Users can draw routes and add them to open area sections. The component
 * allows users to draw new routes (including generating waypoints file) &
 * download local copies
 * @returns {Component} Returns JSX component
 */
export const TeachRoutePage = () => {
  const classes = useStyles();
  const { googleMaps } = useEnvironment();
  const { push } = useHistory();
  const location = useLocation();
  const rootRef = useRef();
  const detailRef = useRef();
  const utm = new utmObj();
  const [connectionError, setConnectionError] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [fileName, setFileName] = useState('');
  const { openAreaID, regionId, propertyId, blockId } = location.state;
  const allPathsFileData = [];
  const allPathsS3Data = useRef([]);

  /**
   * Renders a polyline on the provided Google Maps object and attaches event listeners for
   * interaction with the polyline. The function allows the user to add points
   * ,remove points, and dynamically calculates the total path distance.
   *
   * @param {google.maps.Map} map - The Google Maps object where the polyline is rendered.
   * @param {google.maps} maps - The Google Maps API object used for map rendering.
   */
  const renderPolylines = (map, maps) => {
    /**
     * Calculates the total distance of the polyline and updates the HTML element displaying it.
     * The distance is calculated using the spherical geometry API from Google Maps.
     */
    function calculateDistance() {
      // Compute the length of the polyline path
      pathDistance = google.maps.geometry?.spherical.computeLength(poly.getPath());

      // Update the distance display in the DOM, formatted to 2 decimal places
      document.getElementById('pathDistance').innerHTML = pathDistance.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }

    /**
     * Adds a new LatLng point to the polyline's path.
     * @param {google.maps.LatLng} latLng
     */
    function addLatLngToPoly(latLng) {
      const path = poly.getPath();
      path.push(latLng);
    }

    // Create a new polyline with properties defined
    poly = new google.maps.Polyline({
      strokeColor: '#EA2840', // Red color for the polyline
      strokeOpacity: 1,
      strokeWeight: 3,
      editable: true, // Allows for editing (adding/removing points)
      map: map
    });

    // Event listener for map clicks to add a point to the polyline
    google.maps.event.addListener(map, 'click', (event) => {
      addLatLngToPoly(event.latLng);
      calculateDistance();
    });

    // Event listener for the polyline context menu (right-click) to remove a point
    google.maps.event.addListener(poly, 'contextmenu', (e) => {
      if (e.vertex === undefined) {
        return;
      }
      poly.getPath().removeAt(e.vertex);
      calculateDistance();
    });

    // Event listener for changes to the polyline path (set or insert points)
    google.maps.event.addListener(poly.getPath(), 'set_at', (e) => {
      calculateDistance();
    });

    google.maps.event.addListener(poly.getPath(), 'insert_at', (e) => {
      calculateDistance();
    });
  };

  /**
   * Clears all drawn routes and poylines and resets the map canvas to default
   * @returns None
   */
  function clearClicked() {
    poly?.getPath().clear();
    pathDistance = 0;
    document.getElementById('pathDistance').innerHTML = pathDistance.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  /**
   * Asynchronously generates a CSV file containing a series of vertices with UTM coordinates
   * and heading information based on a polyline. Intermediate points are inserted when the
   * distance between two consecutive points exceeds a predefined threshold.
   *
   * The function processes the polyline's path, converts coordinates to UTM format, calculates
   * headings between points, and stores the resulting data for CSV export.
   *
   * @returns {Promise<void>} - A promise that resolves when the CSV data is generated.
   */
  async function saveCSV() {
    // Get the vertices from the polyline
    const vertices = poly.getPath();
    const length = vertices.getLength();

    // Threshold distance for inserting intermediate points
    const thresholdDistance = 10;
    const finalVertices = [];

    // Loop through each vertex in the polyline
    for (let i = 0; i < length - 1; i++) {
      const currentVertex = vertices.getAt(i);
      const nextVertex = vertices.getAt(i + 1);

      finalVertices.push(currentVertex);

      const currentLat = currentVertex.lat();
      const currentLng = currentVertex.lng();
      const nextLat = nextVertex.lat();
      const nextLng = nextVertex.lng();

      // Convert the current and next points to UTM coordinates
      const currentUtm = utm.convertLatLngToUtm(currentLat, currentLng, 8);
      const nextUtm = utm.convertLatLngToUtm(nextLat, nextLng, 8);

      // Calculate the distance between the two points
      const dx = nextUtm.Easting - currentUtm.Easting;
      const dy = nextUtm.Northing - currentUtm.Northing;
      const distance = Math.sqrt(dx * dx + dy * dy);

      // Insert intermediate points if the distance exceeds the threshold
      if (distance > thresholdDistance) {
        const numIntermediate = Math.floor(distance / thresholdDistance);
        for (let j = 1; j <= numIntermediate; j++) {
          const fraction = j / (numIntermediate + 1);
          const interpLat = currentLat + fraction * (nextLat - currentLat);
          const interpLng = currentLng + fraction * (nextLng - currentLng);
          const intermediatePoint = new google.maps.LatLng(interpLat, interpLng);
          finalVertices.push(intermediatePoint);
        }
      }
    }

    // Add the last vertex
    finalVertices.push(vertices.getAt(length - 1));

    // Prepare the CSV data array
    const csvData = [];

    // Process each vertex for CSV export
    for (let i = 0; i < finalVertices.length; i++) {
      const point = finalVertices[i];
      const lat = point.lat();
      const lng = point.lng();
      const utmValues = utm.convertLatLngToUtm(lat, lng, 8);
      let heading = 0;

      // Calculate heading for the first point using the next point
      if (i === 0 && finalVertices.length > 1) {
        const nextPoint = finalVertices[i + 1];
        const nextLat = nextPoint.lat();
        const nextLng = nextPoint.lng();
        const nextUtm = utm.convertLatLngToUtm(nextLat, nextLng, 8);
        heading = Math.atan2(-(nextUtm.Easting - utmValues.Easting), nextUtm.Northing - utmValues.Northing);
      } else if (i > 0) {
        // Use previous point to calculate heading for all subsequent points
        const prevPoint = finalVertices[i - 1];
        const prevLat = prevPoint.lat();
        const prevLng = prevPoint.lng();
        const prevUtm = utm.convertLatLngToUtm(prevLat, prevLng, 8);
        heading = Math.atan2(-(utmValues.Easting - prevUtm.Easting), utmValues.Northing - prevUtm.Northing);
      }

      // Normalize the heading angle to a specific range
      heading = clampAngle(heading + Math.PI / 2);

      // Convert heading to quaternion
      const euler = [0, 0, heading];
      const quaternion = euler_to_quaternion(euler);

      // Add the point data to the CSV data array
      csvData.push([
        i, // Index
        0, // Timestamp
        utmValues.Easting, // UTM Easting
        utmValues.Northing, // UTM Northing
        0, // Altitude
        quaternion[1], // quaternion x
        quaternion[2], // quaternion y
        quaternion[3], // quaternion z
        quaternion[0], // quaternion w
        heading, // Heading angle
        lng, // Longitude
        lat, // Latitude
        0, // Speed Limit
        0, // Offset Distance
        1, // Blade State
        0 // Unused
      ]);
    }

    // Store the generated CSV data in the csvArray
    csvArray = csvData;
  }

  // Handles downloading a local copy of paths file
  async function handleLocalDownload() {
    await saveCSV();
    const csvContent = `data:text/csv;charset=utf-8,${csvArray.map((e) => e.join(',')).join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  // Handles Exit button when prompted to add more paths
  function handleCancel() {
    push(ROUTE_PATHS_STUDIO);
  }

  const handleShowDialog = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const handleShowConfirmation = (message) => {
    setConfirmationPopup(true);
    setConfirmationMessage(message);
  };

  /**
   * Handles constructing the all_paths file by adding waypoints from the new path,
   * adds the key column to all_paths, and handles uploading path and all_paths files.
   * @returns None
   *
   */
  const handleUploadOpenAreaPath = async () => {
    await saveCSV();
    let parsedAllPathsData = [];
    const csvContent = csvArray.map((e) => e.join(',')).join('\n');
    if (csvArray) {
      // Constructs filename key and adds it to the corresponding waypoints on all_paths file
      const fileKey = `/tmp/open_area/subrows/${fileName}__${regionId}_${propertyId}_${blockId}_${openAreaID}.csv`;
      const formattedAllPathsData = csvArray.map((row) => {
        row.push(fileKey);
        return row;
      });
      if (allPathsS3Data.current?.length > 0) {
        allPathsS3Data.current.forEach((row) => allPathsFileData.push(row));
      }
      formattedAllPathsData.forEach((row) => allPathsFileData.push(row));
      // Contains parsed and formatted all_paths file data
      parsedAllPathsData = allPathsFileData.map((row) => row.join(',')).join('\n');
    }
    if (!csvContent) {
      setConnectionError(true);
      setErrorMessage('Remember to record a path');
    } else {
      handleShowDialog('Uploading the path csv file in progress ...');
      const rowOrdexIndex = fileName?.split('_')[0];
      const result = await addRouteToSubrowsTable(Number(rowOrdexIndex), DEFAULT_OPEN_AREA_PATH_TYPE, openAreaID, fileName);
      const subrowId = result?.data?.results?.id;
      // upload all_paths if there is data
      if (parsedAllPathsData) {
        await uploadAllPathsFile(parsedAllPathsData, openAreaID, regionId, propertyId, blockId);
      }
      // upload path file
      if (subrowId) {
        await uploadOpenAreaFile(csvContent, fileName, openAreaID, regionId, propertyId, blockId);
      }
      setLoading(false);
      handleShowConfirmation('The CSV has successfully been saved, would you like to continue?');
    }
  };

  // Checks the number of existing routes in the subblock or open area
  // and uses the information to set the order index of new path
  const getAndSetSubrowsCounts = async () => {
    const result = await getSubBlockSubrows(openAreaID, DEFAULT_OPEN_AREA_PATH_TYPE);
    const subrowCount = result?.results?.length || 0;
    const orderIndex = Number(subrowCount + 1);
    const indexedFileName = `${orderIndex}_N`;
    setFileName(indexedFileName);
  };

  /**
   * This function checks for an existing all_paths file and
   * stores it as a state variable
   */
  const checkAndRetrieveAllPathsFile = async () => {
    if (regionId && propertyId && blockId && openAreaID) {
      const data = await guardedClient.get('file-manager/all-paths-file', {
        params: { regionId, propertyId, blockId, subBlockId: openAreaID }
      });
      const allPathsResult = data?.data?.result;
      if (allPathsResult) {
        allPathsResult.forEach((row) => allPathsS3Data.current.push(row));
      }
    }
  };
  // hook retrives and stores existing all paths file as state variable on
  // component mount
  useEffect(() => {
    getAndSetSubrowsCounts();
    checkAndRetrieveAllPathsFile();
  }, [confirmationPopup]);

  return useObserver(() => {
    const center = { lat: 43.4627424614825, lng: -80.4755951623591 };

    if (rootRef.current) {
      // Timeout just forces the scroll to the iteration after the render, so the scroll places properly
      setTimeout(() => rootRef.current.scrollIntoView({ behavior: 'smooth' }));
    }

    return (
      <Fade in>
        <div ref={rootRef}>
          <Paper color="inverted" ref={detailRef} className={classes.detailPanel} elevation={0}>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              className={classes.innerPanel}
            >
              <LoadingDialog show={loading} message={loadingMessage} maxWidth="md" />
              <Grid item xs={12} lg={12} className={`${classes.map} ${classes.noPrint}`}>
                <div id="container">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, libraries: 'geometry' }}
                    center={center}
                    defaultZoom={18.56 || googleMaps.defaultZoom}
                    options={{
                      streetViewControl: true,
                      overviewMapControl: true,
                      disableDefaultUI: false,
                      zoomControl: true,
                      mapTypeId: 'hybrid',
                      draggable: true,
                      tilt: 0
                    }}
                    onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
                    yesIWantToUseGoogleMapApiInternals
                  />
                  <div id="sidebar">
                    <ThemeProvider theme={theme}>
                      <Typography style={{ fontWeight: 'bold' }}>Path Distance (m)</Typography>
                      <Divider />
                      <Typography id="pathDistance" style={{ fontSize: 25 }}>
                        0.00
                      </Typography>
                      <Divider />
                    </ThemeProvider>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={clearClicked}
                      color="secondary"
                      fullWidth
                      style={{ marginTop: 15 }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={handleUploadOpenAreaPath}
                      color="secondary"
                      fullWidth
                      style={{ marginTop: 15 }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={handleLocalDownload}
                      color="secondary"
                      fullWidth
                      style={{ marginTop: 15 }}
                    >
                      Download Locally
                    </Button>
                    <TextField
                      id="outlined-basic"
                      label="Path Name"
                      variant="outlined"
                      value={fileName}
                      disabled={fileName !== ''}
                      fullWidth
                      style={{ marginTop: '15px' }}
                    />

                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={handleCancel}
                      color="secondary"
                      fullWidth
                      style={{ marginTop: 15 }}
                    >
                      Exit
                    </Button>
                  </div>
                </div>
              </Grid>
              <ConnectionErrorDialog open={connectionError} handleClose={() => setConnectionError(false)} errorMessage={errorMessage} />
              <SaveRouteConfirmation
                open={confirmationPopup}
                handleContinue={() => {
                  clearClicked();
                  setConfirmationPopup(false);
                }}
                handleExit={() => push(ROUTE_PATHS_STUDIO)}
                confirmationMessage={confirmationMessage}
              />
            </Grid>
          </Paper>
        </div>
      </Fade>
    );
  });
};
