import React, { useEffect } from 'react';
import { Box, Button, IconButton, makeStyles, Typography, Switch, FormControlLabel } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import VideocamIcon from '@material-ui/icons/Videocam';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RefreshIcon from '@material-ui/icons/Refresh';
import { RadioButtonUncheckedRounded } from '@material-ui/icons';
import { STATUS_MAP, IN_USE, PS4_PARAMETERS } from '../../../utils/constants';
import { useStores } from '../../../store/root/root.store';
import { Joystick } from '../../../input';
import { buildTopicAndPayloadForMqttEstop } from '../../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  container: { maxWidth: '100%', boxSizing: 'border-box', marginBottom: '100%', marginRight: '0px' },
  bladeStatusIndicatior: {
    boxSizing: 'border-box',
    marginTop: '5px',
    marginLeft: '5px',
    padding: '2px',
    width: '80%',
    backgroundColor: theme.palette.grey[300],
    display: 'flex',
    borderRadius: '7%',
    '&:hover': {
      backgroundColor: '#d4cecd'
    }
  },
  teleOpsSwitchContainer: {
    width: '100px',
    boxSizing: 'border-box',
    marginLeft: '0px',
    padding: '0px'
  },

  teleOpsSwitch: {
    width: '80%',
    backgroundColor: '#d9d9d9',
    marginTop: '5px',
    borderRadius: '7%',
    marginRight: '0',
    marginLeft: '5px'
  },
  pauseAndPlayButtonIcons: {
    backgroundColor: '',
    color: 'white',
    height: 35,
    width: 35,
    marginLeft: '25px'
  },
  SwEstopButton: {
    marginTop: '5px',
    marginLeft: '5px',
    fontSize: '12px',
    fontWeight: 'bold',
    width: '80%',
    height: '55px'
  },
  pauseAllButton: {
    marginBottom: '5px',
    fontSize: '12px',
    fontWeight: 'bold',
    marginLeft: '5px'
  },
  restartVideoButton: {
    width: '80%',
    marginTop: '-12.5  px',
    marginLeft: '5px'
  },
  refreshVideoButton: {
    width: '80%',
    marginTop: '-12.5  px',
    marginLeft: '5px',
    marginBottom: '5px '
  },
  restartRobotButton: {
    marginTop: '5px',
    marginLeft: '5px',
    width: '80%'
  },
  bladesEnabledIndicator: {
    color: 'green',
    marginLeft: '4px',
    paddingBottom: '2px'
  },
  bladesDisabledIndicator: {
    color: 'red',
    marginLeft: '4px',
    paddingBottom: '2px'
  }
}));

/**
 * Responsible for rendering and handling Autonomy Control Buttons
 * These including
 *     - Starting, Stoping, and Pausing Autonomous paths
 *     - Toggling E-Stop
 *     - Toggling Blades
 *     - Toggling Tele-Ops Mode
 *     - Restarting Robot
 *     - Restarting video feed
 *
 * @param {Array} selectedSolarSubRows array of currently selected sub-rows
 * @param {Boolean} isAutonomyAndTeleopsDisabled Will evaluate to true if streaming is enabled, robot is not estopped, and no other robot in view is in teleops mode
 * @param {Boolean} isSuspendedSubrowDifferent Will evaluate to true if suspended mode with different path type
 * @param {Function} changeRepeatingState Handler for changing autonomy path repeating state
 * @param {Function} setShouldPauseAllRobots Pauses all robots in view if True
 * @param {Function} setEnableAutonmousBladesDialog Dialog to confirm if user wants to enable autonomous blades
 * @param {Boolean} isBladeRunning Checks if blades are currently running
 * @param {Function} toggleAutonomousBlades Handles turning on/off autonomous blades
 * @param {Object} robotConnection A class instance that's responsible for handling Portal to robot connection through ROS.js
 * @param {Function} handlesetTeleopsDrivingEnabled Handler for enabling/disabling tele-ops driving
 * @param {Boolean} teleopsDrivingEnabled Tracks if tele-ops mode is enabled. Remote Guardians can drive robot through portal interface if True
 */
const AutonomyControlButtons = observer(
  ({
    selectedSolarSubRows,
    isAutonomyAndTeleopsDisabled,
    isSuspendedSubrowDifferent,
    changeRepeatingState,
    setShouldPauseAllRobots,
    setEnableAutonmousBladesDialog,
    toggleAutonomousBlades,
    robotConnection,
    handlesetTeleopsDrivingEnabled,
    teleopsDrivingEnabled,
    serialNumber,
    changeDriveState,
    handleJoystickState,
    setShowRestartVideoDialog,
    setIsVideoStreamingRefreshed,
    isVideoStreamingRefreshed,
    userEmail,
    mqttClient
  }) => {
    const classes = useStyles();
    const { MultiRobotsStore } = useStores();
    const robotLightState = MultiRobotsStore.robots.get(serialNumber)?.lightsState;
    const robotWpsState = Number(MultiRobotsStore.robots.get(serialNumber)?.wpsState);
    const robotSwEstopState = MultiRobotsStore.robots.get(serialNumber)?.swEstopState;
    const robotControlMode = MultiRobotsStore.robots.get(serialNumber)?.controlMode;
    const isBladeRunning = MultiRobotsStore.robots.get(serialNumber)?.bladeRunning;
    const [topic, payload] = buildTopicAndPayloadForMqttEstop(!robotSwEstopState, userEmail, serialNumber);

    useEffect(() => {
      if (teleopsDrivingEnabled && !isAutonomyAndTeleopsDisabled) {
        MultiRobotsStore.setTeleopsMode(serialNumber, true);
        changeDriveState('teleops');
      } else if (STATUS_MAP[robotWpsState] !== IN_USE) {
        MultiRobotsStore.setTeleopsMode(serialNumber, false);
        changeDriveState('manual');
      }
    }, [teleopsDrivingEnabled, isAutonomyAndTeleopsDisabled]);

    useEffect(() => {
      if (MultiRobotsStore.hasAutonomousRobotRunning() && teleopsDrivingEnabled) {
        setShouldPauseAllRobots(true);
      }
    }, [teleopsDrivingEnabled]);

    return (
      <div className={classes.container}>
        <div style={{ display: 'none' }}>
          {robotControlMode === 'teleops' && (
            <Joystick
              gamepadIndex={PS4_PARAMETERS.gamepadIndex}
              cwidth={100}
              cheight={100}
              visualized={false}
              handleJoystickState={handleJoystickState}
            />
          )}
        </div>
        {selectedSolarSubRows.length > 0 && (
          <>
            <PlayCircleOutlineIcon
              className={classes.pauseAndPlayButtonIcons}
              color="action"
              style={{
                display: STATUS_MAP[robotWpsState] !== IN_USE ? 'inline' : 'none',
                // Use a ternary operator to set the color to gray when disabled
                color: isAutonomyAndTeleopsDisabled || isSuspendedSubrowDifferent ? 'grey' : 'white'
              }}
              onClick={() => {
                if (!isAutonomyAndTeleopsDisabled && !isSuspendedSubrowDifferent) {
                  changeRepeatingState();
                }
              }}
            />
            <PauseCircleOutlineIcon
              className={classes.pauseAndPlayButtonIcons}
              color="action"
              style={{
                display: STATUS_MAP[robotWpsState] === IN_USE ? 'inline' : 'none'
              }}
              onClick={() => changeRepeatingState()}
            />
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.pauseAllButton}
              onClick={() => setShouldPauseAllRobots(true)}
            >
              Pause All
            </Button>
          </>
        )}
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.refreshVideoButton}
          onClick={() => setIsVideoStreamingRefreshed(!isVideoStreamingRefreshed)}
          title="Refresh Video Streaming"
        >
          <RefreshIcon />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.restartVideoButton}
          onClick={() => setShowRestartVideoDialog(true)}
          title="Restart Video"
        >
          <VideocamIcon />
        </Button>
        <IconButton
          className={classes.bladeStatusIndicatior}
          style={{ backgroundColor: '#e8e4e4' }}
          onClick={() => {
            if (!isBladeRunning) {
              setEnableAutonmousBladesDialog(true);
            } else {
              toggleAutonomousBlades();
            }
          }}
          disabled={STATUS_MAP[robotWpsState] !== IN_USE}
        >
          <Typography>Blades</Typography>
          {isBladeRunning ? (
            <FiberManualRecordIcon className={classes.bladesEnabledIndicator} />
          ) : (
            <RadioButtonUncheckedRounded className={classes.bladesDisabledIndicator} />
          )}
        </IconButton>
        <Button
          variant="contained"
          color={robotSwEstopState ? 'secondary' : 'green'}
          size="small"
          className={classes.SwEstopButton}
          onClick={() => {
            robotConnection?.current?.ros?.cmdSwEstop(!robotSwEstopState);
            mqttClient.current.publish(topic, payload);
          }}
          title={robotSwEstopState ? 'Un-Estop' : 'E-Stop'}
          // disabled={robotEstopState}
        >
          {robotSwEstopState ? 'Un-Estop' : 'E-stop'}
        </Button>
        <Box className={classes.teleOpsSwitchContainer}>
          <FormControlLabel
            value="top"
            className={classes.teleOpsSwitch}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Switch
                color="primary"
                size="small"
                disabled={isAutonomyAndTeleopsDisabled}
                onChange={(e) => {
                  handlesetTeleopsDrivingEnabled(!teleopsDrivingEnabled);
                }}
                value={teleopsDrivingEnabled}
                checked={robotControlMode === 'teleops'}
              />
            }
            label="Tele-Ops"
            labelPlacement="top"
          />
        </Box>
        <Box className={classes.teleOpsSwitchContainer}>
          <FormControlLabel
            value="top"
            className={classes.teleOpsSwitch}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Switch
                color="primary"
                size="small"
                onChange={() => {
                  robotConnection.current?.ros?.cmdLights(!robotLightState);
                }}
                value={robotLightState}
                checked={robotLightState}
              />
            }
            label="Lights"
            labelPlacement="top"
            disabled={!teleopsDrivingEnabled}
          />
        </Box>
      </div>
    );
  }
);

export default AutonomyControlButtons;
