import { Button, Grid, makeStyles, Fade } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_GENERATE_SOLAR_ROWS, ROUTE_TEACH_ROUTE } from './routes';
import { useStores } from '../store/root/root.store';
import RealtimeClient from '../realtimeClient';
import ManageLocations from '../components/ManageLocations';
import { PATHS_STUDIO_PREFIX } from '../utils/localstorage.controller';
import SolarRowsTable from '../components/tables/solar-rows.table';
import { fetchSubBlocks } from '../services/api/subblocks.service';
import { checkIfSurveyFileExists } from '../services/api/subrows.service';
import { guardedClient } from '../utils/axios-instance';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  form: {
    marginTop: theme.spacing(4)
  },
  toggle: {
    backgroundColor: 'white',
    Width: 40,
    height: 50,
    marginTop: theme.spacing(1)
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 290,
    marginBottom: '15px'
  },
  button: {
    marginTop: theme.spacing(1),
    height: 80,
    width: 200,
    backgroundColor: '##0000FF'
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  teachButton: {
    marginTop: theme.spacing(1),
    marginDown: theme.spacing(1),
    marginRight: theme.spacing(2),
    height: 80,
    width: 300,
    fontSize: '20px',
    fontWeight: 'bold',
    border: '1px solid #ddd',
    '&:disabled': {
      backgroundColor: 'grey'
    }
  },
  selectButton: {
    height: '100%',
    width: '100%',
    backgroundColor: '##0000FF'
  },
  tableActions: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  helperText: {
    color: 'red'
  }
}));

export const PathsStudioPage = observer(() => {
  const { regionsStore, chaperonePropertyStore, subrowStore, subBlocksStore, blocksStore, authenticationStore, autonomyRobotStore } =
    useStores();
  const classes = useStyles();
  const { push } = useHistory();
  const region = regionsStore.getSelectedRegion()?.id;
  const property = chaperonePropertyStore.getSelectedProperty()?.id;
  const block = blocksStore.getSelectedBlock()?.id;
  const subBlock = subBlocksStore.getSelectedSubBlock()?.id;
  const isSubBlockOpenArea = subBlocksStore.getSelectedSubBlock()?.open_area === true;
  const selectedPathType = subrowStore.currentPathType;
  const subrowsCount = subrowStore.subrows.length;
  const isManager = !!(authenticationStore.role === 'manager' || authenticationStore.role === 'admin');
  const [isSubBlockSelected, setIsSubBlockSelected] = useState(false);
  const [currentSurveyFile, setCurrentSurveyFile] = useState('');
  const [currentSurveyFileURL, setCurrentSurveyFileURL] = useState('');
  const [pathParameters, setPathParameters] = useState([]);
  const [canEnterPathsStudio, setCanEnterPathsStudio] = useState(false);

  /**
   * Handles entering add/edit subblock
   */
  const handleEnterAddUpdateSubBlock = () => {
    push(ROUTE_GENERATE_SOLAR_ROWS, {
      surveyFile: currentSurveyFile,
      surveyFileURL: currentSurveyFileURL,
      pathParams: pathParameters,
      subBlockSelected: isSubBlockSelected
    });
  };

  /**
   * Handles routing to the teach route page
   */
  const handleEnterTeachRoutes = () => {
    push(ROUTE_TEACH_ROUTE, {
      openAreaID: subBlock,
      regionId: regionsStore.selectedRegionId,
      propertyId: chaperonePropertyStore.selectedPropertyId,
      blockId: blocksStore.selectedBlockId
    });
  };

  const getSubrows = async () => {
    if (isSubBlockOpenArea && subBlock) {
      await subrowStore.getSubrows(region, property, subBlock, 'open_area');
    } else {
      const selectedSubBlock = subBlock && subBlock !== 'NONE' ? subBlock : undefined;
      if (selectedSubBlock && selectedPathType) {
        await subrowStore.getSubrows(region, property, selectedSubBlock, selectedPathType);
      }
    }
  };

  useEffect(() => {
    const bgBox = document.querySelector('#bg-box');
    if (localStorage.getItem('useCase') === 'NON_SOLAR_LAWN_MOWING') {
      bgBox.classList.remove('bg-solar', 'bg-snow');
      bgBox.classList.add('bg-non-solar');
    } else if (localStorage.getItem('useCase') === 'SNOW_PLOWING') {
      bgBox.classList.remove('bg-solar', 'bg-non-solar');
      bgBox.classList.add('bg-snow');
    }
  }, []);

  useEffect(() => {
    const client = RealtimeClient();
    client.connect().then(() => {
      client.onMessageReceived((robotSerialNumber, status, user, currentSubsection, _, timestamp) => {
        autonomyRobotStore.updateRobotStatus(robotSerialNumber, status, user, currentSubsection, _, timestamp);
      });
    });
    return () => {
      if (client) {
        client.closeConnection();
      }
    };
  }, []);

  useEffect(() => {
    if (subBlock && selectedPathType && selectedPathType !== '0') {
      getSubrows();
    } else {
      subrowStore.clearSubrows();
    }
  }, [subBlock, selectedPathType]);

  useEffect(() => {
    if (subBlock !== undefined && subBlock !== null && subBlock !== '') {
      setIsSubBlockSelected(true);
    } else {
      setIsSubBlockSelected(false);
    }
    const getPathParameters = async () => {
      if (subBlock !== undefined) {
        const result = await guardedClient.get('/subblocks/path-params', {
          params: { subBlockId: parseInt(subBlock) }
        });
        if (result?.data?.result) {
          const savedParams = result.data.result;
          setPathParameters(savedParams);
        }
      }
      return null;
    };
    getPathParameters();
  }, [subBlock]);

  // Fetches and sets all twin subblocks for a block whenever
  // block changes
  useEffect(() => {
    const setSubBlocks = async () => {
      if (block) {
        const data = await fetchSubBlocks(block);
        if (data?.results?.length > 0) {
          const subblocks = data.results.map((sb) => ({ id: sb.id, name: sb.name })).sort((a, b) => a.name - b.name);
          if (subblocks?.length) {
            subBlocksStore.setTwinSubBlocks(subblocks);
          }
        }
      }
    };
    setSubBlocks();
  }, [block]);

  // This hook checks if a survey file for the selected sub-block exists
  // Filename and URL are passed as states to the generation page if file exists
  useEffect(() => {
    const getAndSetSurveyFile = async () => {
      if (region && property && block && subBlock && !isSubBlockOpenArea) {
        const fileName = `swapautonomy${region}_${property}_${block}_${subBlock}.csv`;
        const result = await checkIfSurveyFileExists(fileName);
        if (result?.data?.success) {
          setCurrentSurveyFile(fileName);
          setCurrentSurveyFileURL(result.data.url);
        }
      }
      if (!subBlock || subBlock === undefined) {
        setCurrentSurveyFile('');
        setCurrentSurveyFileURL('');
      }
    };
    getAndSetSurveyFile();
    if (!block || block === undefined) setCanEnterPathsStudio(false);
    if (block !== undefined) setCanEnterPathsStudio(true);
  }, [region, property, block, subBlock]);

  return (
    <Fade in>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        <ManageLocations solarMode pagePrefix={PATHS_STUDIO_PREFIX} />
        {isManager && (
          <Grid>
            <Button
              className={classes.teachButton}
              onClick={handleEnterAddUpdateSubBlock}
              variant="contained"
              disableElevation
              color="secondary"
              disabled={!canEnterPathsStudio || isSubBlockOpenArea}
            >
              {`${isSubBlockSelected ? 'Edit' : 'Add'} Solar SubBlock`}
            </Button>
            {isSubBlockOpenArea && (
              <Button
                className={classes.teachButton}
                onClick={handleEnterTeachRoutes}
                variant="contained"
                disableElevation
                color="secondary"
                disabled={!isSubBlockOpenArea}
              >
                Add Route
              </Button>
            )}
          </Grid>
        )}
        {subrowsCount && <SolarRowsTable selectedSubBlock={subBlock} selectedPathType={selectedPathType} />}
      </Grid>
    </Fade>
  );
});
