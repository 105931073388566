import { types } from 'mobx-state-tree';

export const SolarSubrow = types.model({
  id: types.integer,
  created_at: types.string,
  updated_at: types.string,
  status: types.maybeNull(types.string),
  direction: types.maybeNull(types.string),
  order_index: types.maybeNull(types.integer),
  path_type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  displayName: types.maybeNull(types.string),
  is_restricted: types.maybeNull(types.boolean),
  is_cleaned_up: types.maybeNull(types.boolean),
  is_keep_out_zone: types.maybeNull(types.boolean),
  is_muddy: types.maybeNull(types.boolean)
});
