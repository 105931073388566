import React from 'react';
import { FormControl, Grid, Typography, FormHelperText, Select, MenuItem, Button } from '@material-ui/core';

export const ControlledSelectField = ({
  label,
  id,
  value,
  handleSelectOption,
  options,
  classes,
  formError,
  required,
  includeNone,
  isDialog,
  setOpenDialog,
  openDialogText,
  disableOption
}) => (
  <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
    <Typography variant="h6" component="span" className={classes.label}>
      {`${label}:`}
    </Typography>
    <FormControl className={classes.formControl} disabled={disableOption}>
      <Select id={id} value={value} className={classes.select} onChange={handleSelectOption}>
        {includeNone && (
          <MenuItem value={0}>
            <em>NONE</em>
          </MenuItem>
        )}
        {options.map(({ id, name, isDisabled }) => (
          <MenuItem key={id} value={id} disabled={isDisabled}>
            {name}
          </MenuItem>
        ))}
        {isDialog && (
          <Button className={classes.selectButton} onClick={setOpenDialog} variant="contained" disableElevation color="secondary">
            {openDialogText}
          </Button>
        )}
      </Select>
      {!value && required && formError && <FormHelperText className={classes.helperText}>This is required!</FormHelperText>}
    </FormControl>
  </Grid>
);
