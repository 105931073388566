import { guardedClient } from '../../utils/axios-instance';

export const fetchSubBlocks = async (blockId) => {
  const result = await guardedClient.get('/subblocks', { params: { blockId } });
  return result.data;
};

export const addSubBlock = async (subBlock) => {
  const result = await guardedClient.post('/subblocks', subBlock);
  return result.data;
};

export const updateSubBlock = async ({ subBlockId, name }) => {
  const result = await guardedClient.put(`/subblocks/${subBlockId}`, { name });
  return result.data;
};

export const fetchSubBlockById = async (id) => {
  const result = await guardedClient.get(`/subblocks/${id}`);
  return result.data;
};

export const deleteSubBlock = async (id) => {
  const result = await guardedClient.delete(`/subblocks/${id}`);
  return result.data;
};

/**
 * Service is responsible for making a patch request to update
 * sub-block's metadata
 * @param {Number} id Sub-block ID
 * @param {Number} twinSubBlock Twin Sub-Block ID
 * @param {Number} twinRowOffset Row offset for twin sub-block
 * @returns
 */
export const updateSubBlockMeta = async (id, twinSubBlock, twinRowOffset) => {
  const result = await guardedClient.patch('/subblocks/update-subblock', {
    id,
    twinSubBlock,
    twinRowOffset
  });
  return result.data;
};

/**
 * @description Saves path generation parameters for a sub-block.
 *
 * @param {Object} params
 * @param {number} params.subBlockId - ID of the Sub-Block
 * @param {string} params.paramName - Name for the saved parameters
 * @param {number} params.driveLine - Drive line distance
 * @param {number} params.subBlockPanelWidth - Panel width for the sub-block
 * @param {number} params.driveWayWidth - Driveway width
 * @param {number} params.pilePointSeparation - Distance between pile points
 * @param {number} params.robotToPanelDistance - Robot to panel distance
 *
 * @returns {Promise} - Axios response
 */
export const savePathParameters = async (
  subBlockId,
  paramName,
  driveLine,
  subBlockPanelWidth,
  driveWayWidth,
  pilePointSeparation,
  robotToPanelDistance,
  targetOffset
) => {
  const result = await guardedClient.post('/subblocks/path-params', {
    subBlockId,
    paramName,
    driveLine,
    subBlockPanelWidth,
    driveWayWidth,
    pilePointSeparation,
    robotToPanelDistance,
    targetOffset
  });
  return result;
};

/**
 * @description Service function gets all saved path generation parameters
 * belonging to a subblock
 * @param {Number} subBlockId Sub-Block ID
 * @returns {Array} An array of Saved Parameters object
 */
export const getSavedPathGenParameters = async (subBlockId) => {
  if (!Number.isNaN(subBlockId) && subBlockId) {
    const result = await guardedClient.get('/subblocks/path-params', {
      params: { subBlockId: parseInt(subBlockId) }
    });
    if (result?.data?.result) {
      const savedParams = result.data.result;
      return savedParams;
    }
  }
  return null;
};
