import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField, Grid, Typography } from '@material-ui/core';
import { formatStringForReadbility } from '../../utils/ui.utils';
/**
 * A dialog component to save the current path generation parameters.
 *
 * @param {boolean} open - Whether the dialog is open
 * @param {function} onClose - Callback to close the dialog
 * @param {object} pathGenParams - Current parameters to be saved
 * @param {function} onSave - Callback after the user clicks "Save"
 */
const SaveParameterDialog = ({ open, onClose, pathGenParams, onSave }) => {
  const [step, setStep] = useState('NAME');
  const [paramName, setParamName] = useState('');

  const handleNext = () => {
    if (paramName.trim()) {
      setStep('REVIEW');
    }
  };

  /**
   * Return to the "name" step from the review step.
   */
  const handleBack = () => {
    setStep('NAME');
  };

  /**
   * Invokes the onSave callback with the entered name and the current pathGenParams.
   */
  const handleSave = () => {
    onSave(paramName, pathGenParams);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {step === 'NAME' && (
        <>
          <DialogTitle>Save Parameters</DialogTitle>
          <DialogContent>
            <TextField label="Parameter Name" value={paramName} onChange={(e) => setParamName(e.target.value)} fullWidth margin="dense" />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleNext} color="primary" disabled={!paramName.trim()}>
              Next
            </Button>
          </DialogActions>
        </>
      )}

      {step === 'REVIEW' && (
        <>
          <DialogTitle>Review Parameters</DialogTitle>
          <DialogContent>
            <Typography style={{ fontWeight: 'bolder' }} variant="subtitle1" paragraph>
              Parameters Group Name: {paramName}
            </Typography>
            <Grid container spacing={3}>
              {Object.entries(pathGenParams).map(([key, value]) => (
                <React.Fragment key={`${key}_${value}`}>
                  <Grid item xs={6}>
                    <Typography variant="body1">{formatStringForReadbility(key)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{formatStringForReadbility(value) ?? 'NULL'}</Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleBack} color="secondary">
              Back
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default SaveParameterDialog;
