export const ROUTE_ROBOTS = '/robots';
export const ROUTE_ROUTES = '/routes';
export const ROUTE_CONTROL = '/control';
export const ROUTE_AUDIT = '/operations-audit-reports';
export const ROUTE_AUTONOMY_AUDIT_REPORTS = '/autonomy-audit-reports';
export const ROUTE_LOGIN = '/';
export const ROUTE_HOME = '/home';
export const ROUTE_CHAPERONE = '/chaperone';
export const ROUTE_SOLAR = '/solar';
export const ROUTE_PATHS_STUDIO = '/paths-studio';
export const ROUTE_SOLAR_REMOTE = '/solar-remote';
export const ROUTE_SOLAR_MULTI_ROBOTS = '/multi-robots';
export const ROUTE_SOLAR_MULTI_ROBOTS_AUDIT_OFFICER = '/multi-robots-audit-officer';
export const ROUTE_SOLAR_REMOTE_GUARDIAN_REPEAT = '/solar-remote-guardian-repeat';
export const ROUTE_GENERATE_SOLAR_ROWS = '/generate-solar-rows';
export const ROUTE_REPEAT_SOLAR_ROWS = '/repeat-solar-rows';
export const ROUTE_SOLAR_AUDIT_OFFICER_DETAILS = '/audit_officer/solar_audit_details';
export const ROUTE_TEACH_SUBSECTION = '/teach-sub-section';
export const ROUTE_EDIT_WAY_POINTS = '/edit-way-points';
export const ROUTE_VISUALIZE = '/visualize';
export const ROUTE_REPEAT = '/repeat';
export const ROUTE_MANAGE_ROBOTS = '/manage-robots';
export const ROUTE_ADD_ROBOT = '/add-robot';
export const ROUTE_DECLARE_EVENT = '/declare_event';
export const ROUTE_DIAGNOSTICS = '/diagnostics';
export const ROUTE_REMOTE_GUARDIAN = '/remote_guardian';
export const ROUTE_REMOTE_GUARDIAN_REPEAT = '/remote_guardian/repeat';
export const ROUTE_AUDIT_OFFICER = '/audit_officer';
export const ROUTE_AUDIT_OFFICER_DETAILS = '/audit_officer/details';
export const ROUTE_MANAGE_LOCATIONS = '/manage_locations';
export const ROUTE_ADMIN_OPERATIONS = '/admin/operations-kpis';
export const ROUTE_ADMIN_SAFETY = '/admin/safety-kpis';
export const ROUTE_ADMIN_LOCATIONS = '/admin/locations';
export const ROUTE_ADMIN_NEW_LOCATION = '/admin/locations/new';
export const ROUTE_ADMIN_REPORTS = '/admin/reports';
export const ROUTE_ADMIN_USERS = '/admin/users';
export const ROUTE_ADMIN_NEW_USER = '/admin/users/new';
export const ROUTE_TEACH_ROUTE = '/teach-route';
export const ROUTE_ROUTE_SELECTION = '/route-selection';
export const ROUTE_ROUTE_REPEAT = '/route-repeat';
export const ROUTE_CHAPERONE_ESTOP = '/chaperone-estop';
