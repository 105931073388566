import axios from 'axios';
import { Auth } from 'aws-amplify';
import { guardedClient } from '../../utils/axios-instance';

export const addSolarSubrow = async (subblockId, rounteName) => {
  const response = await guardedClient.post('subrows/db', {
    subblockId,
    rounteName
  });
  return response;
};

export const getSubBlockSubrows = async (subBlock, pathType) => {
  const response = await guardedClient.get('/subrows', { params: { subblockId: subBlock, pathType } });
  return response.data;
};

export const getSubrowMetaData = async (subrowId) => {
  const response = await guardedClient.get(`/subrows/${subrowId}`);
  return response.data.results;
};

export const updateSubrow = async (id, data) => {
  const response = await guardedClient.patch(`/subrows/${id}`, data);
  return response.data;
};

export const uploadSubBlockSubrowsFile = async (file, region, property, block, subBlock) => {
  const formdata = new FormData();
  formdata.append('file', file, file.name);
  formdata.append('region', region);
  formdata.append('property', property);
  formdata.append('block', block);
  formdata.append('subblock', subBlock);
  const response = await guardedClient.post('/file-manager/subrows', formdata);
  return response.data;
};

export const generateSubRowsS3 = async (
  s3path,
  driveLineDistance,
  subBlockPanelWidth,
  driveWayWidthDistance,
  pilePointSeparation,
  robotToPanelDistanceLevel,
  generationMethod,
  generationMethodType,
  targetOffsetLevel,
  vegetationThicknessLevel,
  weatherConditionLevel,
  interRowModeSelected,
  subrowsToModify
) => {
  const session = await Auth.currentSession();
  const authorizationHeaders = { Authorization: session.idToken.jwtToken };

  const data = JSON.stringify({
    S3_path: s3path,
    driveLine: driveLineDistance,
    development: process.env.REACT_APP_ENV,
    panelWidth: subBlockPanelWidth,
    driveWayWidth: driveWayWidthDistance,
    pileSeparation: pilePointSeparation,
    robotToPanelDistance: robotToPanelDistanceLevel,
    generateMethod: generationMethod,
    generateMethodType: generationMethodType,
    targetOffset: targetOffsetLevel,
    vegetationThickness: vegetationThicknessLevel,
    weatherCondition: weatherConditionLevel,
    interRowMode: interRowModeSelected,
    subrows: subrowsToModify
  });

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://ro70fp3dj6.execute-api.ca-central-1.amazonaws.com/Stage/TeleOpsServer-PostGenerateSolarPaths',
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaders
    },
    data
  };
  const res = await axios.request(config);
  return res;
};

const getLocationFromS3path = (s3path) => {
  const s3pathParts = s3path.split('swapautonomy');
  const locationPart = s3pathParts[2].split('/');
  return {
    regionId: parseInt(locationPart[0]),
    propertyId: parseInt(locationPart[1]),
    blockId: parseInt(locationPart[2]),
    subblockId: parseInt(locationPart[3])
  };
};

export const getSubBlockPathTypes = async (subBlockId) => {
  const paths = await guardedClient.get('/subrows/path-types/', { params: { subblockId: subBlockId } });
  return paths.data.results;
};

export const createSubRowsDb = async (s3path) => guardedClient.post('/subrows/', { ...getLocationFromS3path(s3path) });

export const getBlockSubRow = async (params) => {
  const response = await guardedClient.get('/subrows', params);
  return response.data.results;
};

export const getSubRowStatus = async (orderIndex, direction, subBlockId) => {
  const response = await guardedClient.get('/subrows/subrow-status', {
    params: {
      orderIndex,
      direction,
      subBlockId
    }
  });
  return response.data;
};

/**
 * Returns subrow metadata including if it's restricted
 * @param {Number} subrowId
 * @returns None
 */
export const getSubrowMeta = async (subrowId) => {
  const response = await guardedClient.get('/subrows/metadata', {
    params: {
      subrowId
    }
  });
  return response.data;
};

/**
 * Updates a specific column in the subrows relation
 * @param {*} subBlockID Sub-block ID
 * @param {*} columnName Column name to be updated
 * @param {*} value New value for the entry
 * @returns
 */
export const updateSolarSubRow = async (subBlockID, columnName, value) => {
  const response = await guardedClient.patch('/subrows/update', {
    subBlockId: subBlockID,
    field: columnName,
    value
  });
  return response.data;
};

/**
 * Makes a batch update to subrow and all its pathtype variants
 * @param {Number} subBlockId Selected subblock ID
 * @param {String} direction Subrow entrance direction
 * @param {Number} orderIndex Subrow order in subblock
 * @param {String} field Table column to be updated
 * @param {String or Boolean} value New column value
 * @returns API response
 */
export const updateSubrowAllPaths = async (subBlockId, direction, orderIndex, field, value) => {
  const response = await guardedClient.patch('/subrows/update-all-subrow-path', {
    subBlockId,
    direction,
    orderIndex,
    field,
    value
  });
  return response;
};

/**
 * This service function is responsible for calling an API that
 * checks if a file exists on S3 and returns its URL if file exists
 * @param {String} surveyFile
 * @returns {Object} respinse object containing API call status and ?file URL
 */
export const checkIfSurveyFileExists = async (surveyFile) => {
  const response = await guardedClient.get('/file-manager/survey-file', {
    params: {
      surveyFileName: surveyFile
    }
  });
  return response;
};

/**
 * Service function responsible for making a DB entry on subrows relation for open area routes
 * @param {Number} orderIndex The order index of route. Determined by the order it was created
 * @param {String} pathType Always set to open_areas by default
 * @param {Number} subBlockId The ID of the opean area (or subblock)
 * @param {String} routeName Given name for route. Default is OrderIndex_North
 * @returns Response
 */
export const addRouteToSubrowsTable = async (orderIndex, pathType, subBlockId, routeName) => {
  if (orderIndex && pathType && subBlockId && routeName) {
    const response = await guardedClient.post('/subrows/route', {
      orderIndex,
      pathType,
      subBlockId,
      routeName
    });
    return response;
  }
  return null;
};
