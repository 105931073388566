/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { drawLine } from '../../utils/canvas.utils';
import { guardedClient } from '../../utils/axios-instance';
import { ensureWebComponentIsLoaded } from '../../utils/external-scripts.utls';
import { useStores } from '../../store/root/root.store';
import { HIGH_LATENCY_THRESHOLD_SECONDS } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'rgba(32, 32, 32, 1.0)',
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '945px'
  },
  video: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  videoOverlay: {
    position: 'absolute',
    width: '100%',
    objectFit: 'contain'
  },
  circularProgress: {
    color: theme.palette.inverted.main
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  webComponent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // video3 -> left, video1 -> rear, video0 -> front, video2 -> right
    '& .webrtc-video3': {
      position: 'absolute',
      left: '0%',
      width: '25.0%'
    },
    '& .webrtc-video1': {
      position: 'absolute',
      top: '400px',
      left: '0%',
      width: '25.0%',
      zIndex: '1'
    },
    '& .webrtc-video0': {
      position: 'absolute',
      left: '25.0%',
      width: '50.0%'
    },
    '& .webrtc-video2': {
      position: 'absolute',
      left: '75.0%',
      width: '25.0%',
      zindex: '1'
    }
  }
}));

export const AutonomyVideo = observer(({ serialNumber }) => {
  const { controlStore } = useStores();
  const isMountedRef = useRef(null);
  const [canvasContext, setCanvasContext] = useState(null);
  const [token, setToken] = useState('');
  const canvasRef = useRef();
  const videoDeviceRef = useRef();
  const classes = useStyles();
  const tempSerialNumber = `d_${serialNumber}`;

  // const stopLoading = () => setLoading(false);

  const getStreamToken = async () => {
    const response = await guardedClient.get('/helpers/stream-token', { params: { serialNumber: tempSerialNumber } });
    setToken(response.data.results.token);
  };

  useEffect(() => {
    if (!serialNumber?.toLowerCase().includes('v71')) {
      // Enabling all robots for transitive except for robots before V7.1
      ensureWebComponentIsLoaded(tempSerialNumber, getStreamToken);
    }
    return () => {
      const script = document.getElementById('webrtc-web-component-video');
      if (script) script.remove();
    };
  }, []);

  // Draw the overlay on canvas
  const draw = useCallback(() => {
    if (!canvasContext) return;
    canvasContext.clearRect(0, 0, canvasRef.current.height, canvasRef.current.width);

    const centerX = canvasRef.current.width / 2;
    const centerStartY = canvasRef.current.height * (7 / 8);
    const centerEndY = canvasRef.current.height / 2;

    // Adjust slope to adjust the angle of lines, adjust margin to adjust margin from center
    const slope = 2;
    const marginX = -70;
    const leftRightMarginY = 30;

    // Function calculates the x coordinate of provided y coordinate on the slope designated (left side)
    const leftXCoordFunc = (y) => (y / slope) * -1 + (centerX - marginX);

    // Function calculates the x coordinate of provided y coordinate on the slope designated (right side)
    const rightXCoordFunc = (y) => y / slope + (centerX + marginX);

    const leftStartY = centerStartY - leftRightMarginY;
    const leftStartX = leftXCoordFunc(leftStartY);
    const leftEndY = centerEndY;
    const leftEndX = leftXCoordFunc(leftEndY);

    const rightStartY = centerStartY - leftRightMarginY;
    const rightStartX = rightXCoordFunc(rightStartY);
    const rightEndY = centerEndY;
    const rightEndX = rightXCoordFunc(rightEndY);

    // Center line
    drawLine({
      ctx: canvasContext,
      points: [
        [centerX, centerStartY],
        [centerX, centerEndY]
      ],
      segments: [5, 5],
      lineWidth: 6
    });
    drawLine({
      ctx: canvasContext,
      points: [
        [centerX, centerStartY],
        [centerX, centerEndY]
      ],
      color: '#FFFFFF',
      segments: [5, 5],
      offset: 5,
      lineWidth: 6
    });

    // Left line
    drawLine({
      ctx: canvasContext,
      points: [
        [leftStartX, leftStartY],
        [leftEndX + 75, leftEndY]
      ],
      color: '#FF0000',
      segments: [5, 5],
      lineWidth: 6
    });
    drawLine({
      ctx: canvasContext,
      points: [
        [leftStartX, leftStartY],
        [leftEndX + 75, leftEndY]
      ],
      color: '#FFFFFF',
      segments: [5, 5],
      offset: 5,
      lineWidth: 6
    });

    // Right line
    drawLine({
      ctx: canvasContext,
      points: [
        [rightStartX, rightStartY],
        [rightEndX - 75, rightEndY]
      ],
      color: '#FF0000',
      segments: [5, 5],
      lineWidth: 6
    });
    drawLine({
      ctx: canvasContext,
      points: [
        [rightStartX, rightStartY],
        [rightEndX - 75, rightEndY]
      ],
      color: '#FFFFFF',
      segments: [5, 5],
      offset: 5,
      lineWidth: 6
    });
    // back center
    drawLine({
      ctx: canvasContext,
      points: [
        [centerX - 960, centerStartY + 125],
        [centerX - 960, centerEndY + 300]
      ],
      segments: [5, 5],
      lineWidth: 6
    });
    drawLine({
      ctx: canvasContext,
      points: [
        [centerX - 960, centerStartY + 125],
        [centerX - 960, centerEndY + 300]
      ],
      color: '#FFFFFF',
      segments: [5, 5],
      offset: 5,
      lineWidth: 6
    });
    // back left
    drawLine({
      ctx: canvasContext,
      points: [
        [leftStartX - 790, leftStartY + 150],
        [leftEndX - 865, leftEndY + 300]
      ],
      color: '#FF0000',
      segments: [5, 5],
      lineWidth: 6
    });
    drawLine({
      ctx: canvasContext,
      points: [
        [leftStartX - 790, leftStartY + 150],
        [leftEndX - 865, leftEndY + 300]
      ],
      color: '#FFFFFF',
      segments: [5, 5],
      offset: 5,
      lineWidth: 6
    });

    // back right line
    drawLine({
      ctx: canvasContext,
      points: [
        [rightStartX - 1135, rightStartY + 150],
        [rightEndX - 1060, rightEndY + 300]
      ],
      color: '#FF0000',
      segments: [5, 5],
      lineWidth: 6
    });
    drawLine({
      ctx: canvasContext,
      points: [
        [rightStartX - 1135, rightStartY + 150],
        [rightEndX - 1060, rightEndY + 300]
      ],
      color: '#FFFFFF',
      segments: [5, 5],
      offset: 5,
      lineWidth: 6
    });
  }, [canvasContext]);

  useEffect(() => {
    isMountedRef.current = true;
    const renderContext = canvasRef?.current?.getContext('2d');
    if (renderContext && isMountedRef.current) {
      setCanvasContext(renderContext);
      draw();
    }

    return () => {
      isMountedRef.current = false;
    };
  }, [draw]);

  // Once the component is mounted, i.e., myref is bound, call the onLag
  // function of the imperative API to attach a listener for lag events
  useEffect(() => {
    if (!videoDeviceRef?.current) return;
    videoDeviceRef?.current?.call('onLag', (lag) => {
      if (lag.lag > HIGH_LATENCY_THRESHOLD_SECONDS) {
        // disable teleops
        controlStore.updateLatency(true, lag.lag);
      } else {
        // enable teleops
        controlStore.updateLatency(false, lag.lag);
      }
    });
  }, [videoDeviceRef?.current]);

  return (
    <div className={classes.root}>
      {token ? (
        <webrtc-video-device
          class={classes.webComponent}
          id="swap"
          host="transitiverobotics.com"
          ssl="true"
          jwt={token}
          use-default="true"
          timeout="86400"
          exclusive="true"
          ref={videoDeviceRef}
        />
      ) : null}
    </div>
  );
});
