import { types } from 'mobx-state-tree';

export const SubBlock = types.model({
  id: types.integer,
  name: types.string,
  block_id: types.integer,
  created_at: types.string,
  updated_at: types.string,
  open_area: types.maybeNull(types.boolean),
  twin_subblock: types.maybeNull(types.integer)
});
