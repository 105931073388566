/* eslint-disable comma-dangle */
/* eslint comma-dangle: ["error", "always-multiline"] */
import ROSLIB from 'roslib';
import { CONNECTION_TIMEOUT_THRESHOLD, JOY_PUBLISH_PERIOD } from '../../utils/constants';

const { v4: uuidv4 } = require('uuid'); // Import UUID library

const iotEventsTopic = String(process.env.REACT_APP_AWS_IOT_EVENT_TOPIC);
export class RosChaperoneService {
  constructor(robotSerial = '', user = '', webpage = '') {
    this.robotSerialNumber = robotSerial;
    this.uuid = uuidv4(); // Generate a UUID
    this.user = user;
    this.webpage = webpage;
  }

  ros = null;

  pc = null;

  listener = null;

  robotStateListener = null;

  isPathDoneTopicListener = null;

  nextPathIsInterRowListener = null;

  rgConfirmationPromptListener = null;

  notificationsListener = null;

  doneListener = null;

  healthcheckLockoutListener = null;

  autonomyBaseMotionLockoutListener = null;

  autonomyLockoutListener = null;

  sensorListener = null;

  onConnectedCallback = () => {};

  cmdVelTopicPublisher = null;

  heartBeatMsgTopicPublisher = null;

  cmdJoyTopicPublisher = null;

  teleopsTurnTopicPublisher = null;

  controlModeTopicPublisher = null;

  navParamsTopicPublisher = null;

  slowzoneCommandTopic = null;

  webSocketLatency = 0;

  timeSinceLastJoyMessagePublished = null;

  // Wayptoings status commands
  static NAV_PAUSE = 'PAUSE';

  static NAV_RESUME = 'RESUME';

  static NAV_START = 'START';

  static NAV_CANCEL = 'CANCEL';

  static NAV_FINISH = 'FINISH';

  static WPS_PAUSE = 'PAUSE';

  static WPS_RESUME = 'RESUME';

  static WPS_START = 'START';

  static WPS_CANCEL = 'CANCEL';

  static WPS_FINISH = 'FINISH';

  // Plow commands
  static PLOW_UP = 'UP';

  static PLOW_DOWN = 'DOWN';

  static PLOW_DOWN_FLOAT = 'DOWN_FLOAT';

  static PLOW_LEFT_FORWARD = 'LEFT_FORWARD';

  static PLOW_LEFT_BACKWARD = 'LEFT_BACKWARD';

  static PLOW_RIGHT_FORWARD = 'RIGHT_FORWARD';

  static PLOW_RIGHT_BACKWARD = 'RIGHT_BACKWARD';

  static PLOW_STOP = 'STOP';

  connect(onConnected = () => {}, onDisconnected = () => {}) {
    this.onConnectedCallback = onConnected;
    this.onDisconnectedCallback = onDisconnected;
    const connectionUrl = this.constructConnectionUrl();

    this.ros = new ROSLIB.Ros({
      url: connectionUrl
    });

    // Initialize the connectionCheckInterval
    this.connectionCheckInterval = null;

    // Set handlers for connection errors and successful connections
    this.ros.on('error', (error) => {
      const errorMsg = error.error || error;
      console.error('ROS error:', errorMsg);
      this.handleConnectionError();
    });

    this.ros.on('connection', () => {
      console.info('ROS connected');
      if (this.cmdJoyTopicPublisher) {
        this.cmdJoyTopicPublisher = null;
      }
      if (this.timeSinceLastJoyMessagePublished) {
        this.timeSinceLastJoyMessagePublished = null;
      }
      this.onConnectedCallback();

      // Start the periodic check to see if Robotstate topic message was recently received
      this.connectionCheckInterval = setInterval(() => {
        const timeSinceLastMessage = Date.now() - this.lastReceivedMessageTime;
        this.webSocketLatency = timeSinceLastMessage;
        if (timeSinceLastMessage > CONNECTION_TIMEOUT_THRESHOLD) {
          console.error('Connection seems to be lost, no heartbeat received.');
          this.handleConnectionError();
        }
      }, 1100); // check every 1.1 second
    });

    this.ros.on('close', () => {
      console.info('ROS connection closed');
      this.handleConnectionError();
    });

    try {
      this.ros.connect('ros');
    } catch (e) {
      this.handleConnectionError();
      throw e;
    }
  }

  constructConnectionUrl() {
    return `wss://roswss.swapautonomy.ca/${this.robotSerialNumber}/?uuid=${this.uuid}&user=${this.user}&webpage=${this.webpage}`;
  }

  handleConnectionError() {
    if (this.connectionCheckInterval) {
      clearInterval(this.connectionCheckInterval);
    }
    this.onDisconnectedCallback();
  }

  close() {
    if (this.ros) this.ros.close();
  }

  /**
   * Sends a command to move the robot.
   */
  publishVelocity(cmdVel) {
    // Create a Topic object with details of the topic's name and message type.
    if (!cmdVel) return;

    this.cmdVelTopicPublisher = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/teleops/cmd_vel`,
      messageType: 'geometry_msgs/Twist'
    });

    // Create the payload to be published. Properties are as defined in the geometry_msgs/Twist.msg definition
    const commandMsg = new ROSLIB.Message({
      linear: {
        x: cmdVel.linear,
        y: 0.0,
        z: 0.0
      },
      angular: {
        x: 0.0,
        y: 0.0,
        z: cmdVel.angular
      }
    });

    // console.debug('Sending operator command to robot:', commandMsg);
    this.cmdVelTopicPublisher.publish(commandMsg);
  }

  publishJoy(buttons, axes, timestamp) {
    // Create a Topic object with details of the topic's name and message type.
    if (!axes) return;

    const now = Date.now();
    if (this.timeSinceLastJoyMessagePublished && now - this.timeSinceLastJoyMessagePublished < JOY_PUBLISH_PERIOD) {
      return;
    }

    this.timeSinceLastJoyMessagePublished = now;

    if (!this.cmdJoyTopicPublisher) {
      this.cmdJoyTopicPublisher = new ROSLIB.Topic({
        ros: this.ros,
        name: `/${this.robotSerialNumber}/teleops_joy`,
        messageType: 'sensor_msgs/Joy'
      });
    }

    // Create the payload to be published. Properties are as defined in the geometry_msgs/Twist.msg definition
    const secs = Math.floor(timestamp);
    const nsecs = Math.round((timestamp - secs) * 1000000000.0);
    const commandMsg = new ROSLIB.Message({
      header: { stamp: { secs, nsecs } },
      axes,
      buttons
    });
    this.cmdJoyTopicPublisher.publish(commandMsg);
  }

  setControlMode(controlMode) {
    if (!controlMode) return;
    this.controlModeTopicPublisher = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/web_portal_control_mode`,
      messageType: 'std_msgs/String'
    });

    const commandMsg = new ROSLIB.Message({
      data: controlMode
    });

    // console.debug('Sending operator command to robot:', commandMsg);

    this.controlModeTopicPublisher.publish(commandMsg);
  }

  publishTeleopsTurn(twist) {
    if (!twist) return;

    this.teleopsTurnTopicPublisher = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/teleops/auto_turn`,
      messageType: 'std_msgs/Float32'
    });

    // Create the payload to be published. Properties are as defined in the geometry_msgs/Twist.msg definition
    const commandMsg = new ROSLIB.Message({
      data: twist
    });

    // console.debug('Sending operator command to robot:', commandMsg);
    this.teleopsTurnTopicPublisher.publish(commandMsg);
  }

  /**
   * Publish Params message to the robot to control autonomous navigation behavior
   * Params: "max_linear_speed,lookahead_distance,turning_x_threshold,turning_y_threshold"
   */
  updateNavParams(params = null) {
    // Create a Topic object with details of the topic's name and message type.
    if (!params) return;

    this.navParamsTopicPublisher = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/repeat/navigation_params`,
      messageType: 'std_msgs/String'
    });

    // Create the payload to be published. Properties are as defined in the geometry_msgs/Twist.msg definition
    const paramsMsg = new ROSLIB.Message({
      data: params
    });

    // console.debug('Sending operator command to robot:', paramsMsg);
    this.navParamsTopicPublisher.publish(paramsMsg);
  }

  sendSlowZoneCmd(command = null) {
    // Create a Topic object with details of the topic's name and message type.
    if (!command) return;

    this.slowzoneCommandTopic = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/teach/slowzone`,
      messageType: 'std_msgs/String'
    });

    // Create the payload to be published. Properties are as defined in the geometry_msgs/Twist.msg definition
    const commandMsg = new ROSLIB.Message({
      data: command
    });

    // console.debug('Sending operator command to robot:', commandMsg);
    this.slowzoneCommandTopic.publish(commandMsg);
  }

  /**
   * Sends an audit request message, an ad hoc audit record in addition to automatic ones.
   * @param {Object} param0
   */
  sendAuditRequest() {
    // Create a Topic object with details of the topic's name and message type.
    this.auditRequestTopic = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/audit_request`,
      messageType: 'std_msgs/String'
    });

    // Create the payload to be published. future enhancement could
    // allow sending a tag or comment to be associated with the audit record produced.
    const request = new ROSLIB.Message({ data: 'operator' });
    console.debug('Sending audit request message to robot:');
    this.auditRequestTopic.publish(request);
  }

  /**
   * Publish Command message to the robot to control peripherals
   */
  publishCommand(command = null, args = []) {
    // Create a Topic object with details of the topic's name and message type.
    if (!command) return;
    this.operatorCommandTopic = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/operator_commands`,
      messageType: `${iotEventsTopic}_msgs/OperatorCommand`
    });

    // Create the payload to be published. Properties are as defined in the geometry_msgs/Twist.msg definition
    const commandMsg = new ROSLIB.Message({ command, args });

    console.debug('Sending operator command to robot:', commandMsg);
    this.operatorCommandTopic.publish(commandMsg);
  }

  getLastWpsIndexService(handler = () => {}) {
    const getLastWpsIndexService = new ROSLIB.Service({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/pure_pursuit/last_wps_index`,
      serviceType: 'Trigger'
    });

    const request = new ROSLIB.ServiceRequest({});
    // console.log('request is ', request);
    getLastWpsIndexService.callService(request, (result) => {
      console.log(`Result for service call on ${getLastWpsIndexService.name}: ${result.message}`);
      handler(result.message);
    });
  }

  cmdRobotService(command = null, args = [], handler = () => {}) {
    const cmdRobotService = new ROSLIB.Service({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/cmd_robot_service`,
      serviceType: `${iotEventsTopic}_msgs/Process`
    });

    const request = new ROSLIB.ServiceRequest({
      command,
      args
    });
    cmdRobotService.callService(request, (result) => {
      console.log(`Result for service call on ${cmdRobotService.name}: ${result.error_message}`);
      handler(result);
    });
  }
  /**
   * ROS service call to send parameter configurations from Edit Robots page
   * @param {String} command Service command name
   * @param {JSON} payload Payload sent with the service call
   * @param {Function} callback Callback function to handle service call result
   */

  async cmdRobotPayloadService(command = null, payload = '', callback = () => {}) {
    const cmdRobotPayloadService = new ROSLIB.Service({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/cmd_robot_payload_service`,
      serviceType: `${iotEventsTopic}_msgs/PayloadProcess`
    });

    const request = new ROSLIB.ServiceRequest({
      command,
      payload
    });
    await cmdRobotPayloadService.callService(request, (result) => {
      console.log(`Result for service call on ${cmdRobotPayloadService.name}: ${result.error_message}`);
      callback(result);
    });
  }

  /**
   * ROS service call to get attachment and motion controller details
   * @param {Function} handler - Call-back function to configure attachment and motion controller
   * @returns {None} - None.
   */
  cmdGetAttachmentDetails(handler = () => {}) {
    const cmdRobotService = new ROSLIB.Service({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/get_motion_attachment_controllers`,
      serviceType: `${iotEventsTopic}_std_srvs/Trigger`
    });
    const request = new ROSLIB.ServiceRequest({});
    cmdRobotService.callService(request, (result) => {
      handler(result);
    });
  }

  /**
   * ROS service call to start inter-row path to the next solar row
   * @param {Function} handler - Call-back function to configure inter-row path status
   * @returns {None} - None.
   */
  startInterRowCmdService(payload = null, handler = () => {}) {
    const startInterRowPath = new ROSLIB.Service({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/mission/start_inter_row`,
      serviceType: 'std_srvs/SetBool'
    });

    const request = new ROSLIB.ServiceRequest(payload);
    startInterRowPath.callService(request, (result) => {
      console.log(`Result for service call on ${startInterRowPath.name}: ${result.message}`);
      handler(result);
    });
  }

  /**
   * ROS service call to turn on or turn off autonomous blades on the robot
   * @param {Function} handler The callback for handling the request response
   * @returns {None} - None
   */
  setAutonomousBladeState(payload = null, handler = () => {}) {
    const toggleAutonomousBlades = new ROSLIB.Service({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/set_autonomous_blades_state`,
      serviceType: 'std_srvs/SetBool'
    });
    const request = new ROSLIB.ServiceRequest(payload);
    toggleAutonomousBlades.callService(request, (result) => {
      console.log(`Result for service call on ${toggleAutonomousBlades.name}: ${result.message}`);
      handler(result);
    });
  }
  /**
   * ROS service call to remove the current active client
   * @param {Function} handler - Call-back function to kick-out the connected active rg client
   * @returns {None} - None.
   */
  cmdRemoveCurrentActiveClient(handler = () => {}) {
    const cmdRemoveCurrentActiveClient = new ROSLIB.Service({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/remove_current_active_client`,
      serviceType: 'Trigger'
    });

    const request = new ROSLIB.ServiceRequest({});
    cmdRemoveCurrentActiveClient.callService(request, (result) => {
      console.log(`Result for service call on ${cmdRemoveCurrentActiveClient.name}: ${result.success}`);
      handler(result);
    });
  }

  /**
   * ROS service call to start inter-row path to the next solar row
   * @param {Function} handler - Call-back function to configure inter-row path status
   * @returns {None} - None.
   */
  rgConfirmationPromptService(payload = null, handler = () => {}) {
    const rgConfirmationPromptServiceRequest = new ROSLIB.Service({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/mission/rg_confirmation_prompt_command`,
      serviceType: 'swap_srvs/RGConfirmationPromptService'
    });

    const request = new ROSLIB.ServiceRequest(payload);
    rgConfirmationPromptServiceRequest.callService(request, (result) => {
      console.log(`Result for service call on ${rgConfirmationPromptServiceRequest.name}: ${result.message}`);
      handler(result);
    });
  }

  /**
   * ROS param get call to get updated nav params
   * @param {Function} handler - Call-back function to handle params value
   * @returns {None} - None.
   */
  getUpdatedStaticConfig(handler = () => {}) {
    const attachmentTypeParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/tool_type'
    });
    const portalTargetParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/portal_target'
    });
    const isRightOffsetDeckParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/is_right_offset_deck'
    });
    const enableInterRowHoppingParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/enable_inter_row_hopping'
    });
    const enableDevBotParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/enable_dev_bot'
    });
    const acceptableZedLevelParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/acceptable_zed_level'
    });
    const enableAutonomousAttachmentControlParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/enable_autonomous_attachment_control'
    });
    const enableAutonomousBladesDuringAutonomyParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/enable_autonomous_blades_during_autonomy'
    });
    const enableManualBladesDuringAutonomyParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/enable_manual_blades_during_autonomy'
    });
    const autonomyAttachmentControllerParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/autonomy_attachment_controller'
    });
    const autonomyNavigationControllerParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/autonomy_navigation_controller'
    });
    const autonomyNavigationDeviationCheckParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/autonomy_navigation_deviation_check'
    });
    const attachmentOffsetDirection = new ROSLIB.Param({
      ros: this.ros,
      name: '/swap_control_manager_node/tool_configuration'
    });
    const interrowType = new ROSLIB.Param({
      ros: this.ros,
      name: '/interrow_type'
    });
    const overworkedMotorsCheck = new ROSLIB.Param({
      ros: this.ros,
      name: '/bypass_motors_overworked_checks'
    });
    const dynamicMowingSpeed = new ROSLIB.Param({
      ros: this.ros,
      name: '/enable_dynamic_mowing_speed'
    });
    const stuckAutonomyCheck = new ROSLIB.Param({
      ros: this.ros,
      name: '/stuck_autonomy/check'
    });
    const stuckSpeedThreshold = new ROSLIB.Param({
      ros: this.ros,
      name: '/autonomy_speed_threshold'
    });
    const stuckExecutionProgress = new ROSLIB.Param({
      ros: this.ros,
      name: '/stuck_autonomy/execution_progress_threshold'
    });
    const stuckCurrentThreshold = new ROSLIB.Param({
      ros: this.ros,
      name: '/stuck_autonomy/drive_current_threshold'
    });
    const stuckTimeout = new ROSLIB.Param({
      ros: this.ros,
      name: '/stuck_autonomy/timeout'
    });
    const enableRosbagRecording = new ROSLIB.Param({
      ros: this.ros,
      name: '/rosbag_recording_enabled'
    });
    const enableRosbagUploading = new ROSLIB.Param({
      ros: this.ros,
      name: '/rosbag_uploading_enabled'
    });
    const enableBaseSensor = new ROSLIB.Param({
      ros: this.ros,
      name: '/check_base_sensor'
    });
    const enableTankSensor = new ROSLIB.Param({
      ros: this.ros,
      name: '/check_tank_sensor'
    });
    const enableAdasChaperone = new ROSLIB.Param({
      ros: this.ros,
      name: '/dynamic_params/enable_adas_chaperone'
    });
    const coolingHoseEnabled = new ROSLIB.Param({
      ros: this.ros,
      name: '/swap_control_manager_node/roughmower/has_cooling_hose'
    });
    const deckPotWorks = new ROSLIB.Param({
      ros: this.ros,
      name: '/swap_control_manager_node/roughmower/deck_potentiometer/is_installed'
    });
    const deckPotHomeValue = new ROSLIB.Param({
      ros: this.ros,
      name: '/swap_control_manager_node/roughmower/deck_potentiometer/offset_calibration'
    });
    const framePotWorks = new ROSLIB.Param({
      ros: this.ros,
      name: '/swap_control_manager_node/roughmower/frame_potentiometer/is_installed'
    });
    const framePotHomeValue = new ROSLIB.Param({
      ros: this.ros,
      name: '/swap_control_manager_node/roughmower/frame_potentiometer/offset_calibration'
    });
    const paramsToFetch = [
      { param: attachmentTypeParam, resultName: 'attachmentType' },
      { param: portalTargetParam, resultName: 'portalTarget' },
      { param: isRightOffsetDeckParam, resultName: 'isRightOffsetDeck' },
      { param: enableInterRowHoppingParam, resultName: 'enableInterRowHopping' },
      { param: enableDevBotParam, resultName: 'enableDevBot' },
      { param: acceptableZedLevelParam, resultName: 'acceptableZedLevel' },
      { param: enableAutonomousAttachmentControlParam, resultName: 'enableAutonomousAttachmentControl' },
      { param: enableAutonomousBladesDuringAutonomyParam, resultName: 'enableAutonomousBladesDuringAutonomy' },
      { param: enableManualBladesDuringAutonomyParam, resultName: 'enableManualBladesDuringAutonomy' },
      { param: autonomyAttachmentControllerParam, resultName: 'autonomyAttachmentController' },
      { param: autonomyNavigationControllerParam, resultName: 'autonomyNavigationController' },
      { param: autonomyNavigationDeviationCheckParam, resultName: 'autonomyNavigationDeviationCheck' },
      { param: attachmentOffsetDirection, resultName: 'attachmentOffsetDirection' },
      { param: interrowType, resultName: 'interrowType' },
      { param: overworkedMotorsCheck, resultName: 'overworkedMotorsCheck' },
      { param: dynamicMowingSpeed, resultName: 'dynamicMowingSpeed' },
      { param: stuckAutonomyCheck, resultName: 'stuckAutonomyCheck' },
      { param: stuckSpeedThreshold, resultName: 'stuckSpeedThreshold' },
      { param: stuckExecutionProgress, resultName: 'stuckExecutionProgress' },
      { param: stuckCurrentThreshold, resultName: 'stuckCurrentThreshold' },
      { param: stuckTimeout, resultName: 'stuckTimeout' },
      { param: enableRosbagRecording, resultName: 'enableRosbagRecording' },
      { param: enableRosbagUploading, resultName: 'enableRosbagUploading' },
      { param: enableBaseSensor, resultName: 'enableBaseSensor' },
      { param: enableTankSensor, resultName: 'enableTankSensor' },
      { param: enableAdasChaperone, resultName: 'enableAdasChaperone' },
      { param: coolingHoseEnabled, resultName: 'coolingHoseEnabled' },
      { param: deckPotWorks, resultName: 'deckPotWorks' },
      { param: deckPotHomeValue, resultName: 'deckPotHomeValue' },
      { param: framePotWorks, resultName: 'framePotWorks' },
      { param: framePotHomeValue, resultName: 'framePotHomeValue' }
    ];

    const results = {};
    let fetchedCount = 0;

    const handleResult = (resultName, value) => {
      if (value !== null && value !== undefined) {
        results[resultName] = value;
      }
      fetchedCount++;

      if (fetchedCount === paramsToFetch.length) {
        // All parameters fetched, call the handler with the results
        handler(results);
      }
    };

    paramsToFetch.forEach(({ param, resultName }) => {
      param.get((value) => {
        handleResult(resultName, value);
      });
    });
  }

  getDeviationCheck(handler = () => {}) {
    const param = new ROSLIB.Param({
      ros: this.ros,
      name: 'autonomy_navigation_deviation_check'
    });
    const portalTargetParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/portal_target'
    });
    const isRightOffsetDeckParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/is_right_offset_deck'
    });
    const enableInterRowHoppingParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/enable_inter_row_hopping'
    });
    const enableDevBotParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/enable_dev_bot'
    });
    const acceptableZedLevelParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/acceptable_zed_level'
    });
    const enableAutonomousAttachmentControlParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/enable_autonomous_attachment_control'
    });
    const enableAutonomousBladesDuringAutonomyParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/enable_autonomous_blades_during_autonomy'
    });
    const enableManualBladesDuringAutonomyParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/enable_manual_blades_during_autonomy'
    });
    const autonomyAttachmentControllerParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/autonomy_attachment_controller'
    });
    const autonomyNavigationControllerParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/autonomy_navigation_controller'
    });
    const autonomyNavigationCeviationCheckParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/autonomy_navigation_deviation_check'
    });
    const paramsToFetch = [
      { param: attachmentTypeParam, resultName: 'attachmentType' },
      { param: portalTargetParam, resultName: 'portalTarget' },
      { param: isRightOffsetDeckParam, resultName: 'isRightOffsetDeck' },
      { param: enableInterRowHoppingParam, resultName: 'enableInterRowHopping' },
      { param: enableDevBotParam, resultName: 'enableDevBot' },
      { param: acceptableZedLevelParam, resultName: 'acceptableZedLevel' },
      { param: enableAutonomousAttachmentControlParam, resultName: 'enableAutonomousAttachmentControl' },
      { param: enableAutonomousBladesDuringAutonomyParam, resultName: 'enableAutonomousBladesDuringAutonomy' },
      { param: enableManualBladesDuringAutonomyParam, resultName: 'enableManualBladesDuringAutonomy' },
      { param: autonomyAttachmentControllerParam, resultName: 'autonomyAttachmentController' },
      { param: autonomyNavigationControllerParam, resultName: 'autonomyNavigationController' },
      { param: autonomyNavigationCeviationCheckParam, resultName: 'autonomyNavigationDeviationCheck' }
    ];

    const results = {};
    let fetchedCount = 0;

    const handleResult = (resultName, value) => {
      if (value !== null && value !== undefined) {
        results[resultName] = value;
      }
      fetchedCount++;

      if (fetchedCount === paramsToFetch.length) {
        // All parameters fetched, call the handler with the results
        handler(results);
      }
    };

    paramsToFetch.forEach(({ param, resultName }) => {
      param.get((value) => {
        handleResult(resultName, value);
      });
    });
  }

  /**
   * ROS param get call to get updated nav params
   * @param {Function} handler - Call-back function to handle params value
   * @returns {None} - None.
   */
  getUpdatedDynamicConfig(handler = () => {}) {
    const maxLinearSpeedParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/motion_controller_manager_node/max_linear_velocity'
    });
    const maxAngularSpeedParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/motion_controller_manager_node/max_rotational_velocity'
    });
    const minLookAheadDistanceParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/motion_controller_manager_node/min_lookahead_distance'
    });
    const videoGammaParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/teleops_video/video_gamma'
    });
    const videoContrastParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/teleops_video/video_contrast'
    });
    const isLowStreamingEnabledParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/dynamic_params/enable_low_bandwidth'
    });
    const isTeleopsStreamingEnabledParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/dynamic_params/enable_streaming'
    });
    const deviationPositionSlowSpeedParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/dynamic_params/autonomy_navigation_deviation_slow_speed'
    });
    const deviationPositionStopThresholdParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/dynamic_params/autonomy_navigation_position_deviation_stop_threshold'
    });
    const deviationPositionSlowThresholdParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/dynamic_params/autonomy_navigation_position_deviation_slow_threshold'
    });
    const deviationHeadingStopThresholdParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/dynamic_params/autonomy_navigation_heading_deviation_stop_threshold_rad'
    });
    const deviationHeadingSlowThresholdParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/dynamic_params/autonomy_navigation_heading_deviation_slow_threshold_rad'
    });
    const autonomyNavigationModeParam = new ROSLIB.Param({
      ros: this.ros,
      name: '/dynamic_params/autonomy_navigation_mode'
    });
    const interRowDirection = new ROSLIB.Param({
      ros: this.ros,
      name: '/autonomy_manager/inter_row_dir'
    });
    const paramsToFetch = [
      { param: maxLinearSpeedParam, resultName: 'linearSpeed' },
      { param: maxAngularSpeedParam, resultName: 'angularSpeed' },
      { param: minLookAheadDistanceParam, resultName: 'lookAheadDistance' },
      { param: videoGammaParam, resultName: 'videoGamma' },
      { param: videoContrastParam, resultName: 'videoContrast' },
      { param: isTeleopsStreamingEnabledParam, resultName: 'isTeleopsStreamingEnabled' },
      { param: isLowStreamingEnabledParam, resultName: 'isLowStreamingEnabled' },
      { param: deviationPositionSlowSpeedParam, resultName: 'deviationPositionSlowSpeed' },
      { param: deviationPositionStopThresholdParam, resultName: 'deviationPositionStopThreshold' },
      { param: deviationPositionSlowThresholdParam, resultName: 'deviationPositionSlowThreshold' },
      { param: deviationHeadingStopThresholdParam, resultName: 'deviationHeadingStopThreshold' },
      { param: deviationHeadingSlowThresholdParam, resultName: 'deviationHeadingSlowThreshold' },
      { param: autonomyNavigationModeParam, resultName: 'autonomyNavigationMode' },
      { param: interRowDirection, resultName: 'interRowDirection' }
    ];

    const results = {};
    let fetchedCount = 0;

    const handleResult = (resultName, value) => {
      if (value !== null && value !== undefined) {
        // Apply conversions for linear speed and angular speed
        if (resultName === 'linearSpeed') {
          value *= 3.6; // Convert m/s to km/h
        } else if (resultName === 'angularSpeed') {
          value *= 57.6; // Convert rad/s to degrees/s
        }
        results[resultName] = value;
      }
      fetchedCount++;

      if (fetchedCount === paramsToFetch.length) {
        // All parameters fetched, call the handler with the results
        handler(results);
      }
    };

    paramsToFetch.forEach(({ param, resultName }) => {
      param.get((value) => {
        handleResult(resultName, value);
      });
    });
  }

  isWebSocketDisconnected(handler = () => {}) {
    this.disconnectedClientListener = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/active_client_to_be_kicked_off`,
      // this definitely does not work
      messageType: 'rosbridge_msgs/ConnectedClient',
      throttle_rate: 300, // ms between messages
      queue_length: 0 // want only the latest messegae
    });

    this.disconnectedClientListener.subscribe((message) => {
      handler(this.uuid === message.uuid);
    });
  }

  subscribeToRobotStateStamped(handler = () => {}) {
    this.robotStateListener = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/robot_state`,
      messageType: `${iotEventsTopic}_msgs/RobotStateStamped`,
      throttle_rate: 300, // ms between messages
      queue_length: 0 // want only the latest messegae
    });

    this.robotStateListener.subscribe((message) => {
      this.lastReceivedMessageTime = Date.now();
      handler(message);
    });
  }

  // TODO
  /** Subscribes to path/is_done topic. Topic returns Boolean True when robot completes a row */
  subscribeToIsPathDoneTopic(handler = () => {}) {
    this.isPathDoneTopicListener = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/path/is_done`,
      messageType: 'std_msgs/Bool',
      throttle_rate: 1000, // ms between messages
      queue_length: 0 // retrieve latest message only
    });

    this.isPathDoneTopicListener.subscribe((message) => {
      handler(message);
    });
  }

  // TODO
  /** Subscribes to /mission/is_next_path_inter_row topic. Topic returns Boolean if next path is inter-row path */
  subscribeToNextPathIsInterRow(handler = () => {}) {
    this.nextPathIsInterRowListener = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/mission/is_next_path_inter_row`,
      messageType: 'std_msgs/Bool',
      throttle_rate: 1000, // ms between messages
      queue_length: 0 // retrieve latest message only
    });

    this.nextPathIsInterRowListener.subscribe((message) => {
      handler(message);
    });
  }
  /** Subscribes to /mission/is_next_path_inter_row topic. Topic returns Boolean if next path is inter-row path */
  subscribeToRgConfirmationPrompt(handler = () => {}) {
    this.rgConfirmationPromptListener = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/mission/rg_confirmation_prompt`,
      messageType: 'swap_msgs/RGConfirmationPrompt',
      throttle_rate: 1000, // ms between messages
      queue_length: 0 // retrieve latest message only
    });

    this.rgConfirmationPromptListener.subscribe((message) => {
      handler(message);
    });
  }

  /**
   * Subscribes to the log_error topic of the robot
   * @param {*} handler
   */
  subscribeToRobotNotification(handler = () => {}) {
    this.notificationsListener = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/ops_notifications`,
      messageType: 'std_msgs/String',
      throttle_rate: 1000, // ms between messages
      queue_length: 0 // want only the latest messegae
    });

    this.notificationsListener.subscribe((message) => {
      handler(message);
    });
  }

  subscribeToDoneRepeatingMission(handler = () => {}) {
    this.doneListener = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/mission/is_done`,
      messageType: 'std_msgs/Bool',
      throttle_rate: 1000, // ms between messages
      queue_length: 0 // want only the latest messegae
    });

    this.doneListener.subscribe((message) => {
      handler(message);
    });
  }

  subscribeToAutonomyHealthcheckLockout(handler = () => {}) {
    this.healthcheckLockoutListener = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/autonomy_healthcheck_motion_lockout`,
      messageType: 'std_msgs/Bool',
      throttle_rate: 1000, // ms between messages
      queue_length: 0 // want only the latest messegae
    });

    this.healthcheckLockoutListener.subscribe((message) => {
      handler(message);
    });
  }

  subscribeToAutonomyBaseMotionLockout(handler = () => {}) {
    this.autonomyBaseMotionLockoutListener = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/twist_only_autonomous_motion_lockout`,
      messageType: 'std_msgs/Bool',
      throttle_rate: 1000, // ms between messages
      queue_length: 0 // want only the latest messegae
    });

    this.autonomyBaseMotionLockoutListener.subscribe((message) => {
      handler(message);
    });
  }

  subscribeToAutonomyLockout(handler = () => {}) {
    this.autonomyLockoutListener = new ROSLIB.Topic({
      ros: this.ros,
      name: `/${this.robotSerialNumber}/autonomous_motion_lockout`,
      messageType: 'std_msgs/Bool',
      throttle_rate: 1000, // ms between messages
      queue_length: 0 // want only the latest messegae
    });

    this.autonomyLockoutListener.subscribe((message) => {
      handler(message);
    });
  }

  /* Commands to control peripherals:

    command_args = {
      'BEEPER': 1,    # ON | OFF
      'LIGHTS': 2,    # FRONT,                  ON | OFF
      'PLOW': 1,      # UP | DOWN | DOWN_FLOAT | LEFT_FORWARD | LEFT_BACKWARD | RIGHT_FORWARD | RIGHT_BACKWARD
      'SALTER': 1,    # ON | OFF | <level>
      'WIPER': 1      # <level>
    }
  */

  /**
   * Publish an AUDIT message on the operator commands topic
   */
  triggerAudit() {
    this.publishCommand('AUDIT', ['MANUAL']);
  }

  /**
   * Turn beeper on or off. Pass a bool, True means on.
   * @param onOff Pass true to turn beeper on
   */
  cmdBeeper(onOff) {
    this.publishCommand('BEEPER', [onOff ? 'ON' : 'OFF']);
  }

  /**
   * Turn lights on or off. Pass a bool, True means on.
   * @param onOff Pass true to turn lights on
   */
  cmdLights(onOff) {
    this.publishCommand('LIGHTS', ['FRONT', onOff ? 'ON' : 'OFF']);
  }

  cmdSalterOnOff(onOff) {
    console.log(onOff);
    this.publishCommand('SALTER', [onOff ? 'ON' : 'OFF']);
  }

  /**
   * Set salter level. 0 is off.
   * @param level. Range is 0 to 5.
   */
  cmdSalterSpeed(level = 0) {
    this.publishCommand('SALTER', ['SET', level.toString()]);
  }

  /**
   * Send a plow command.
   * @param arg one of UP | DOWN | DOWN_FLOAT | LEFT_FORWARD | LEFT_BACKWARD | RIGHT_FORWARD | RIGHT_BACKWARD
   */
  cmdPlow(arg) {
    this.publishCommand('PLOW', [arg]);
  }

  /**
   * Set wiper speed. 0 is off.
   * @param speed Wiper set, 0 is off. Range is 0 to 2
   */
  cmdWiper(speed = 0) {
    this.publishCommand('WIPER', [speed.toString()]);
  }

  /**
   * Turn beeper on or off. Pass a bool, True means on.
   * @param onOff Pass true to turn beeper on
   */
  cmdSwEstop(onOff, userEmail, isAdmin) {
    if (userEmail && isAdmin) {
      this.cmdRobotService('SW_ESTOP', [onOff ? 'ON' : 'OFF', this.user, userEmail, 'True']);
    } else if (userEmail && !isAdmin) {
      this.cmdRobotService('SW_ESTOP', [onOff ? 'ON' : 'OFF', this.user, userEmail, 'False']);
    } else {
      this.cmdRobotService('SW_ESTOP', [onOff ? 'ON' : 'OFF', this.user]);
    }
  }

  restartRobotService(service) {
    this.publishCommand('RESTART', [service]);
  }
}
