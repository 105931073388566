/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import { Switch, Route, useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, Box, unstable_createMuiStrictModeTheme } from '@material-ui/core';
import { setupRootStore, RootStoreProvider } from './store/root/root.store';
import OperationsPage from './pages/OperationsKpisPage/operations-kpis.page';

import {
  LoginPage,
  ChaperonePage,
  HomePage,
  EditWayPointsPage,
  VisualizePage,
  RepeatPage,
  TeachSubSectionPage,
  ManageRobotsPage,
  AddRobotPage,
  TeachRoutePage,
  DeclareEventPage,
  DiagnosticsPage,
  RemoteGuardianPage,
  RemoteGuardianRepeatPage,
  OperationsAuditReportsPage,
  AuditOfficerPage,
  AutonomyAuditReportsPage,
  PathsStudioPage,
  GenerateSolarPathsPage,
  ManageLocationsPage,
  ManageUsersPage,
  AddUserPage,
  SolarRemoteGuardianPage,
  AddLocationPage,
  RouteSelectionPage,
  RouteRepeatPage,
  MultiRobotsPage,
  MultiRobotsAuditOfficerPage,
  RepeatSolarSubRowsPage,
  ChaperoneEstopPage
} from './pages';
import {
  ROUTE_LOGIN,
  ROUTE_CHAPERONE,
  ROUTE_EDIT_WAY_POINTS,
  ROUTE_VISUALIZE,
  ROUTE_REPEAT,
  ROUTE_ADD_ROBOT,
  ROUTE_MANAGE_ROBOTS,
  ROUTE_TEACH_ROUTE,
  ROUTE_DECLARE_EVENT,
  ROUTE_DIAGNOSTICS,
  ROUTE_REMOTE_GUARDIAN,
  ROUTE_REMOTE_GUARDIAN_REPEAT,
  ROUTE_TEACH_SUBSECTION,
  ROUTE_AUDIT,
  ROUTE_AUTONOMY_AUDIT_REPORTS,
  ROUTE_AUDIT_OFFICER,
  ROUTE_AUDIT_OFFICER_DETAILS,
  ROUTE_HOME,
  ROUTE_PATHS_STUDIO,
  ROUTE_GENERATE_SOLAR_ROWS,
  ROUTE_REPEAT_SOLAR_ROWS,
  ROUTE_SOLAR_REMOTE,
  ROUTE_SOLAR_AUDIT_OFFICER_DETAILS,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_NEW_USER,
  ROUTE_ADMIN_OPERATIONS,
  ROUTE_ADMIN_SAFETY,
  ROUTE_ADMIN_LOCATIONS,
  ROUTE_ADMIN_NEW_LOCATION,
  ROUTE_ADMIN_REPORTS,
  ROUTE_SOLAR_REMOTE_GUARDIAN_REPEAT,
  ROUTE_ROUTE_SELECTION,
  ROUTE_ROUTE_REPEAT,
  ROUTE_SOLAR_MULTI_ROBOTS,
  ROUTE_SOLAR_MULTI_ROBOTS_AUDIT_OFFICER,
  ROUTE_CHAPERONE_ESTOP
} from './pages/routes';
import TopBar from './components/core/topbar.component';
import { Body } from './components/core/body.component';
import { PrivateRoute } from './components/core/private-route.component';
import { ErrorPopup } from './components/core/error-popup.component';
import { EnvironmentContext } from './environment';
import { AuditOfficerRepeatPage } from './pages/auditOfficerPage/AuditOfficerDetailsPage';
import AdminNavBar from './components/AdminNavBar';
import { AdminReportsPage } from './pages/adminReportsPage/admin-reports.page';
import { SolarRemoteGuardianRepeatPage } from './pages/solar-remote-guardian-repeat.page';
import SafetyPage from './pages/SafetyKpisPage/safety-kpis.page';
import { NON_SOLAR_LAWN_MOWING, SNOW_PLOWING, SOLAR_LAWN_MOWING } from './utils/constants';
import { SolarAuditOfficerRepeatPage } from './pages/auditOfficerPage/SolarAuditOfficerDetailsPage';
import { LoadingPage } from './pages/loading.page';

// eslint-disable-next-line react/function-component-definition
const App = () => {
  const [rootStore, setRootStore] = useState();
  const { pathname: currentRoute } = useLocation();

  useEffect(() => {
    setRootStore(setupRootStore());
  }, [setRootStore]);
  const StoreEnv = useMemo(() => ({ state: rootStore?.applicationStore.getEnvironment() }), [rootStore]);

  const checkValidSolarRoute = () => {
    if (!localStorage.getItem('useCase')) return false;
    if (![SNOW_PLOWING, NON_SOLAR_LAWN_MOWING].includes(localStorage.getItem('useCase'))) return true;
    return false;
  };

  const checkUseCase = () => {
    if (!localStorage.getItem('useCase')) return false;
    return true;
  };

  const checkValidNoneSolarRoute = () => {
    if (!localStorage.getItem('useCase')) return false;
    if (![SOLAR_LAWN_MOWING].includes(localStorage.getItem('useCase'))) return true;
    return false;
  };

  if (!rootStore) {
    return <LoadingPage />;
  }

  // Using unstable_createMuiStrictModeTheme here to hide console warnings in material ui until they resolve it for real
  // TODO: Switch to createMuiTheme when issue fixed: https://github.com/mui-org/material-ui/issues/13394
  const theme = unstable_createMuiStrictModeTheme({
    palette: {
      primary: {
        main: '#414042'
      },
      inverted: {
        main: '#ffffff'
      },
      secondary: {
        main: '#EA2840',
        contrastText: '#fff' // button text white instead of black
      },
      background: {
        default: '#414042'
      },
      action: {
        disabledBackground: 'gray'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1060,
        lg: 1280,
        xl: 1920
      }
    },
    typography: {
      fontFamily: 'Exo'
    },
    overrides: {
      MuiButton: {
        iconSizeMedium: {
          '& > *:first-child': {
            fontSize: 48
          }
        }
      }
    }
  });

  console.debug('Constructed theme:', theme);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RootStoreProvider value={rootStore}>
        <EnvironmentContext.Provider value={StoreEnv}>
          <SnackbarProvider maxSnack={5}>
            <ErrorPopup />
            <Switch>
              <PrivateRoute path="/admin">
                <AdminNavBar>
                  <Switch>
                    <PrivateRoute exact path={ROUTE_ADMIN_OPERATIONS}>
                      <OperationsPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={ROUTE_ADMIN_USERS}>
                      <ManageUsersPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={ROUTE_ADMIN_NEW_USER}>
                      <AddUserPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={ROUTE_ADMIN_SAFETY}>
                      <SafetyPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={ROUTE_ADMIN_LOCATIONS}>
                      <ManageLocationsPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={ROUTE_ADMIN_NEW_LOCATION}>
                      <AddLocationPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={ROUTE_ADMIN_REPORTS}>
                      <AdminReportsPage />
                    </PrivateRoute>
                  </Switch>
                </AdminNavBar>
              </PrivateRoute>
              <Route path="/">
                <Box className="bg-solar" id="bg-box" display="flex" flexDirection="column" height="100vh">
                  <Box>
                    <TopBar />
                  </Box>
                  <Box flexGrow="1">
                    <Body>
                      <Switch>
                        <PrivateRoute exact path={ROUTE_REPEAT}>
                          <RepeatPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_VISUALIZE}>
                          <VisualizePage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_TEACH_SUBSECTION}>
                          <TeachSubSectionPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_EDIT_WAY_POINTS}>
                          <EditWayPointsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_HOME}>
                          <HomePage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_CHAPERONE} checkValidRoute={checkValidNoneSolarRoute}>
                          <ChaperonePage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_PATHS_STUDIO} checkValidRoute={checkValidSolarRoute}>
                          <PathsStudioPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_SOLAR_REMOTE} checkValidRoute={checkValidSolarRoute}>
                          <SolarRemoteGuardianPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_SOLAR_MULTI_ROBOTS} checkValidRoute={checkValidSolarRoute}>
                          <MultiRobotsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_SOLAR_MULTI_ROBOTS_AUDIT_OFFICER} checkValidRoute={checkValidSolarRoute}>
                          <MultiRobotsAuditOfficerPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_GENERATE_SOLAR_ROWS}>
                          <GenerateSolarPathsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_REPEAT_SOLAR_ROWS}>
                          <RepeatSolarSubRowsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_AUDIT}>
                          <OperationsAuditReportsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_AUTONOMY_AUDIT_REPORTS}>
                          <AutonomyAuditReportsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_MANAGE_ROBOTS}>
                          <ManageRobotsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_ADD_ROBOT}>
                          <AddRobotPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_DECLARE_EVENT}>
                          <DeclareEventPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_DIAGNOSTICS}>
                          <DiagnosticsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_REMOTE_GUARDIAN_REPEAT}>
                          <RemoteGuardianRepeatPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_REMOTE_GUARDIAN} checkValidRoute={checkValidNoneSolarRoute}>
                          <RemoteGuardianPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_AUDIT_OFFICER} checkValidRoute={checkUseCase}>
                          <AuditOfficerPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_AUDIT_OFFICER_DETAILS}>
                          <AuditOfficerRepeatPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_SOLAR_AUDIT_OFFICER_DETAILS}>
                          <SolarAuditOfficerRepeatPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_SOLAR_REMOTE_GUARDIAN_REPEAT}>
                          <SolarRemoteGuardianRepeatPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_TEACH_ROUTE}>
                          <TeachRoutePage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_ROUTE_SELECTION}>
                          <RouteSelectionPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_ROUTE_REPEAT}>
                          <RouteRepeatPage />
                        </PrivateRoute>
                        <PrivateRoute exact path={ROUTE_CHAPERONE_ESTOP}>
                          <ChaperoneEstopPage />
                        </PrivateRoute>
                        <Route exact path={ROUTE_LOGIN}>
                          <LoginPage />
                        </Route>
                      </Switch>
                    </Body>
                  </Box>
                </Box>
              </Route>
            </Switch>
          </SnackbarProvider>
        </EnvironmentContext.Provider>
      </RootStoreProvider>
    </ThemeProvider>
  );
};

export default App;
